import { Card } from '@material-ui/core';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../../../theme/recharts.css'

const SalespersonReportChart = ({ data, isMobile, loading, selectedYear }) => {

  const CustomTooltip = ({ active, payload }) => {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: 10, borderRadius: '4px', border: '2px solid #F14924', fontFamily: "'Roboto', sans-serif" }}>
        <p style={{ marginBottom: 5 }}><strong>{payload[0]?.payload.Name}</strong></p>
        <p>Total: ${payload[0]?.payload.Sales.toFixed(2)}</p>
        <p style={{ color: payload[0]?.color }}>January: ${payload[0]?.payload.January.toFixed(2)}</p>
        <p style={{ color: payload[1]?.color }}>February: ${payload[1]?.payload.Febuary.toFixed(2)}</p>
        <p style={{ color: payload[2]?.color }}>March: ${payload[2]?.payload.March.toFixed(2)}</p>
        <p style={{ color: payload[3]?.color }}>April: ${payload[3]?.payload.April.toFixed(2)}</p>
        <p style={{ color: payload[4]?.color }}>May: ${payload[4]?.payload.May.toFixed(2)}</p>
        <p style={{ color: payload[5]?.color }}>June: ${payload[5]?.payload.June.toFixed(2)}</p>
        <p style={{ color: payload[6]?.color }}>July: ${payload[6]?.payload.July.toFixed(2)}</p>
        <p style={{ color: payload[7]?.color }}>August: ${payload[7]?.payload.August.toFixed(2)}</p>
        <p style={{ color: payload[8]?.color }}>September: ${payload[8]?.payload.September.toFixed(2)}</p>
        <p style={{ color: payload[9]?.color }}>October: ${payload[9]?.payload.October.toFixed(2)}</p>
        <p style={{ color: payload[10]?.color }}>November: ${payload[10]?.payload.November.toFixed(2)}</p>
        <p style={{ color: payload[11]?.color }}>December: ${payload[11]?.payload.December.toFixed(2)}</p>
      </div>
    );
  };

  return (
    <>
      {(!loading && !isMobile) && (
        <Card style={{ padding: 30, marginTop: 30, marginBottom: 30 }}>
          <ResponsiveContainer width="100%" height={data.length === 1 ? 150 : data.length * 100}>
            <BarChart data={data} layout='vertical'>
              <YAxis dataKey="Name" type={'category'} width={200} fontfamily={'Roboto, sans-serif'} interval={0} />
              <XAxis dataKey="Sales" type={'number'} tickFormatter={(v) => { return `$${v.toFixed(2)}` }} />
              <Bar dataKey="January" fill="#6929c4" stackId="a" />
              <Bar dataKey="Febuary" fill="#1192e8" stackId="a" />
              <Bar dataKey="March" fill="#005d5d" stackId="a" />
              <Bar dataKey="April" fill="#9f1853" stackId="a" />
              <Bar dataKey="May" fill="#570408" stackId="a" />
              <Bar dataKey="June" fill="#198038" stackId="a" />
              <Bar dataKey="July" fill="#002d9c" stackId="a" />
              <Bar dataKey="August" fill="#ee538b" stackId="a" />
              <Bar dataKey="September" fill="#b28600" stackId="a" />
              <Bar dataKey="October" fill="#009d9a" stackId="a" />
              <Bar dataKey="November" fill="#012749" stackId="a" />
              <Bar dataKey="December" fill="#8a3800" stackId="a" />
              <Tooltip cursor={{ fill: 'rgba(0,0,0,0.2)' }} content={<CustomTooltip />} />
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      )}
    </>
  );
};

export default SalespersonReportChart;
