import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Modal,
  Snackbar,
  Alert
} from '@material-ui/core';
import {
  X as WrongIcon,
  Check as CorrectIcon
} from 'react-feather';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { fixedHolidays, dynamicHolidays } from '../../constants/holidayLists';
const axios = require('axios');
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const options = [
  {
    value: 'To Customer',
    name: 'To Customer'
  },
  {
    value: 'Showroom',
    name: 'Showroom'
  },
  {
    value: 'Warehouse',
    name: 'Warehouse'
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
};

const nextAvailableDate = () => {
  var result = false;
  var count = 1;
  while (!result) {
    var date = moment().add(count, 'days');
    if (date.format('ddd') === 'Sat' || date.format('ddd') === 'Sun' || fixedHolidays.includes(date.format('DD-MM')) || dynamicHolidays.includes(date.format('DD-MM-YYYY'))) {
      count += 1;
    } else {
      result = true;
    }
  }
  return date.format('YYYY-MM-DD');
};

const DeliveryDetails = ({ handleNext, handleBack, existingSalesOrder }) => {

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyDiiJy9t3xmtl4_W4UrNLG5WfdQbSF3wGE',
    onPlaceSelected: (place) => inputAddress(place),
    options: {
      types: ["address"],
      componentRestrictions: { country: 'au' }
    },
  });

  const [deliveryOption, setDeliveryOption] = useState(existingSalesOrder ? existingSalesOrder.deliveryOption : 'To Customer');
  const [postCode, setPostCode] = useState(existingSalesOrder ? existingSalesOrder.shipToPostcode : '');
  const [suburbList, setSuburbList] = useState([]);
  const [name, setName] = useState(existingSalesOrder ? existingSalesOrder.shipToName : '');
  const [suburb, setSuburb] = useState(existingSalesOrder ? existingSalesOrder.shipToCity : '');
  const [address, setAddress] = useState(existingSalesOrder ? existingSalesOrder.shipToAddress : '');
  const [address2, setAddress2] = useState(existingSalesOrder ? existingSalesOrder.shipToAddress2 : '');
  const [deliveryDate, setDeliveryDate] = useState(existingSalesOrder ? (existingSalesOrder.requestedDeliveryDate === '0001-01-01' ? nextAvailableDate() : existingSalesOrder.requestedDeliveryDate) : moment().format('YYYY-MM-DD'));
  const [removeAppliance, setRemoveAppliance] = useState(existingSalesOrder.salesorderline.filter(so => so.No === '6112').length ? true : false);
  const [stairs, setStairs] = useState(existingSalesOrder ? existingSalesOrder.stairs : false);
  const [lift, setLift] = useState(existingSalesOrder ? existingSalesOrder.lift : false);
  const [warranty, setWarranty] = useState(existingSalesOrder ? existingSalesOrder.emailUpdates : true);
  const [deliveryComment, setDeliveryComment] = useState(existingSalesOrder ? existingSalesOrder.specialDeliveryComment1 : '');
  const [removeApplianceBackup, setRemoveApplianceBackup] = useState(existingSalesOrder.salesorderline.filter(so => so.No === '6112').length ? true : false);
  const [state, setState] = useState(existingSalesOrder ? existingSalesOrder.shipToState : '');

  const [open, setOpen] = useState(false);

  const [calendarFocus, setCalendarFocus] = useState(false);

  const [distributorCode, setDistributorCode] = useState(existingSalesOrder.distributorCode ? existingSalesOrder.distributorCode : '');
  const [customerDelivery, setCustomerDelivery] = useState(null);

  const [nameTouched, setNameTouched] = useState(existingSalesOrder?.shipToName ? true : false);
  const [nameError, setNameError] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState('');

  const [deliveryDateTouched, setDeliveryDateTouched] = useState(existingSalesOrder?.requestedDeliveryDate ? true : false);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  const [deliveryDateErrMsg, setDeliveryDateErrMsg] = useState('');

  const [addressTouched, setAddressTouched] = useState(existingSalesOrder?.shipToAddress ? true : false);
  const [addressError, setAddressError] = useState(false);
  const [addressErrMsg, setAddressErrMsg] = useState('');

  const [suburbTouched, setSuburbTouched] = useState(existingSalesOrder?.shipToCity ? true : false);
  const [suburbError, setSuburbError] = useState(false);
  const [suburbErrMsg, setSuburbErrMsg] = useState('');

  const [postCodeTouched, setPostCodeTouched] = useState(existingSalesOrder?.shipToPostcode ? true : false);
  const [postCodeError, setPostCodeError] = useState(false);
  const [postCodeErrMsg, setPostCodeErrMsg] = useState('');

  const [suburbLoading, setSuburbLoading] = useState(false);

  const [fieldDisable, setFieldDisable] = useState(existingSalesOrder?.deliveryOption === 'To Customer' ? false : true);

  const [saving, setSaving] = useState(false);
  const [deliveryPostcodeVerifying, setDeliveryPostcodeVerifying] = useState(false);
  const [snackbar, setSnackbar] = useState({});

  const isMobile = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    if (existingSalesOrder) {
      setSaving(true);
      updateSuburb(existingSalesOrder.shipToPostcode, existingSalesOrder.shipToCity);
    }
  }, []);

  useEffect(() => {
    if (existingSalesOrder && existingSalesOrder?.customer && (!existingSalesOrder?.deliveryOption || existingSalesOrder?.deliveryOption === "To_x0020_Customer")) {
      handleDeliveryOptionChange("To Customer")
    }
  }, [existingSalesOrder])

  useEffect(() => {
    if (existingSalesOrder && existingSalesOrder.deliveryOption === 'To Customer') {
      setDeliveryPostcodeVerifying(true);
      verifyPostcode(existingSalesOrder.shipToPostcode, 'To Customer');
    }
  }, []);

  const handleClose = () => {
    setSnackbar({})
  }

  const inputAddress = (place) => {
    const unitNumber = place.address_components[0]?.long_name;
    const streetName = place.address_components[1]?.long_name;
    const fullAddress = `${unitNumber} ${streetName}`;
    setAddress(fullAddress);
  };

  const verifyShippingDetails = (inDetails, option) => {
    if (option === 'Showroom') {
      if (inDetails.displayName && inDetails.addressLine1 && inDetails.postalCode && inDetails.city && inDetails.state) {
        return true
      }
    } else if (option === 'Warehouse') {
      if (inDetails.name && inDetails.postCode && inDetails.suburb && inDetails.address && inDetails.state) {
        return true
      }
    }

    return false;
  }

  const verifyPostcode = (inPostcode, inDeliveryOption) => {
    axios.get(`${ServerURL}/post-codes/distributor-postcode/${inPostcode}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (inDeliveryOption === 'To Customer') {
        setCustomerDelivery(res.data.customerDelivery);
      } else {
        setCustomerDelivery(null);
      }
      setDistributorCode(res.data.distributorCode);
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: "Failed to verify postcode",
        severity: "error"
      });
      setDeliveryPostcodeVerifying(false);
    }).finally(() => setDeliveryPostcodeVerifying(false));
  };

  const handleDeliveryOptionChange = (newValue) => {
    if (newValue !== deliveryOption) {
      setSaving(true);
      setDeliveryPostcodeVerifying(true);
      setDeliveryOption(newValue);
      if (newValue === 'To Customer') {
        setFieldDisable(false);
        setName(existingSalesOrder?.customer?.displayName);
        // setSuburb(existingSalesOrder.customer.city);
        setPostCode(existingSalesOrder?.customer?.postalCode);
        verifyPostcode(existingSalesOrder?.customer?.postalCode, newValue);
        updateSuburb(existingSalesOrder?.customer?.postalCode, existingSalesOrder?.customer?.city);
        setAddress(existingSalesOrder?.customer?.addressLine1);
        setAddress2(existingSalesOrder?.customer?.addressLine2);
        setState(existingSalesOrder?.customer?.state);
        setSaving(false);
      } else if (newValue === 'Showroom') {
        setFieldDisable(true);
        setPostCodeError(false);
        setSuburbError(false);
        setSuburbErrMsg("");
        setPostCodeErrMsg("");
        axios.get(`${ServerURL}/agents/get-agent`, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          if (res.data.value.length && verifyShippingDetails(res.data.value[0], newValue) === true) {
            var data = res.data.value[0];
            setName(data.displayName);
            // setSuburb(data.city);
            setPostCode(data.postalCode);
            verifyPostcode(data.postalCode, newValue);
            updateSuburb(data.postalCode, data.city);
            setAddress(data.addressLine1);
            setAddress2(data.addressLine2);
            setState(data.state);
            setRemoveAppliance(false);
            setPostCodeTouched(true);
          } else {
            setOpen(true);
            setDeliveryPostcodeVerifying(false);
          }
        }).catch(err => {
          console.log(JSON.stringify(err.response));
          setSnackbar({
            message: "Failed to fetch showroom information",
            severity: "error"
          });
          setSaving(false);
        }).finally(() => setSaving(false));
      } else if (newValue === 'Warehouse') {
        setFieldDisable(true);
        setPostCodeError(false);
        setSuburbError(false);
        setSuburbErrMsg("");
        setPostCodeErrMsg("");
        axios.get(`${ServerURL}/agents/get-agent-shipto-address`, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          if (res.data.value.length && verifyShippingDetails(res.data.value[0], newValue) === true) {
            var data = res.data.value[0];
            setName(data.name);
            setPostCode(data.postCode);
            verifyPostcode(data.postCode, newValue);
            updateSuburb(data.postCode, data.suburb)
            setAddress(data.address);
            setAddress2(data.address2);
            setState(data.state);
            setRemoveAppliance(false);
            setPostCodeTouched(true);
            setSuburb(true);
          } else {
            setOpen(true);
            setDeliveryPostcodeVerifying(false);
          }
        }).catch(err => {
          console.log(JSON.stringify(err.response));
          setSnackbar({
            message: "Failed to fetch warehouse information",
            severity: "error"
          });
          setSaving(false);
        }).finally(() => setSaving(false));
      }
    }
  }

  const renderSuburbs = suburbList.map((s, i) => (
    <MenuItem value={s.City} key={s.City}>{s.City}</MenuItem>
  ));

  const updateSuburb = (pc, sub) => {
    setSuburbLoading(true);
    axios.get(`${ServerURL}/post-codes/${pc}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (!res.data.value.length) {
        setPostCodeError(true);
        setPostCodeErrMsg('Please enter valid postcode');
        setSuburb('');
        setSuburbList([]);
        setSuburbTouched(false);
      } else {
        setPostCodeError(false);
        setPostCodeErrMsg('');
        if (sub && res.data.value.filter(d => d.City === sub).length === 0) {
          if (res.data.value.length) {
            setState(res.data.value[0].County);
          }
          setSuburbList([{
            '@odata.etag': '123',
            'City': sub
          }]);
          setSuburb(sub ? sub : '');
          setSuburbTouched(true);
        } else {
          if (res.data.value.length) {
            setState(res.data.value[0].County);
          }
          setSuburbList(res.data.value);
          setSuburb(sub ? sub : '');
          setSuburbTouched(true);
        }
        if (!sub) setSuburbTouched(false);
      }
    }).finally(() => { setSuburbLoading(false); setSaving(false) });
  };

  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'postcode':
        setPostCodeTouched(true);
        if (e.target.value.length !== 4) {
          setPostCodeError(true);
          setPostCodeErrMsg('Please enter valid postcode');
          setSuburb('');
          setSuburbList([]);
        } else {
          setSaving(true);
          setDeliveryPostcodeVerifying(true);
          setPostCodeError(false);
          setPostCodeErrMsg('');
          updateSuburb(e.target.value, null);
          verifyPostcode(e.target.value, 'To Customer');
        }
        break;
      case 'name':
        setNameTouched(true);
        if (e.target.value === '') {
          setNameError(true);
          setNameErrMsg('Please enter name');
        } else {
          setNameError(false);
          setNameErrMsg('');
        }
        break;
      case 'deliveryDate':
        setDeliveryDateTouched(true);
        if (e.target.value === '') {
          setDeliveryDateError(true);
          setDeliveryDateErrMsg('Please enter delivery date');
        } else {
          setDeliveryDateError(false);
          setDeliveryDateErrMsg('');
        }
        break;
      case 'suburb':
        setSuburbTouched(true);
        if (e.target.value === '') {
          setSuburbError(true);
          setSuburbErrMsg('Please select a suburb');
        } else {
          setSuburbError(false);
          setSuburbErrMsg('');
        }
        break;
      case 'address':
        setAddressTouched(true);
        if (e.target.value === '') {
          setAddressError(true);
          setAddressErrMsg('Please enter address');
        } else {
          setAddressError(false);
          setAddressErrMsg('');
        }
        break;
    }
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'removeAppliance':
        setRemoveAppliance(e.target.checked);
        break;
      case 'name':
        setName(e.target.value);
        break;
      case 'deliveryOption':
        handleDeliveryOptionChange(e.target.value);
        break;
      case 'postcode':
        setPostCode(e.target.value);
        break;
      case 'suburb':
        setSuburb(e.target.value);
        break;
      case 'address':
        setAddress(e.target.value);
        break;
      case 'address2':
        setAddress2(e.target.value);
        break;
      case 'stairs':
        setStairs(e.target.checked);
        break;
      case 'lift':
        setLift(e.target.checked);
        break;
      case 'warranty':
        setWarranty(e.target.checked);
        break;
      case 'deliveryComment':
        if (e.target.value.length < 50) {
          setDeliveryComment(e.target.value);
        }
        break;
      default:
        setSnackbar({
          message: "Invalid field",
          severity: "warning"
        });
        break;
    }
  };

  const renderOptions = options.map(s => (
    <MenuItem value={s.value} key={s.value}>{s.name}</MenuItem>
  ));

  const handleSave = () => {
    if (nameTouched
      && deliveryDateTouched
      && postCodeTouched
      && addressTouched
      && suburbTouched
      && !nameError
      && !deliveryDateError
      && !postCodeError
      && !addressError
      && !suburbError
      && (deliveryOption === 'To Customer' ? customerDelivery : true)) {
      // setSaving(true);
      var compareObj = {
        deliveryOption: existingSalesOrder.deliveryOption,
        shipToName: existingSalesOrder.shipToName,
        shipToAddress: existingSalesOrder.shipToAddress,
        shipToAddress2: existingSalesOrder.shipToAddress2,
        shipToPostcode: existingSalesOrder.shipToPostcode,
        shipToCity: existingSalesOrder.shipToCity,
        requestedDeliveryDate: existingSalesOrder.requestedDeliveryDate,
        stairs: existingSalesOrder.stairs,
        lift: existingSalesOrder.lift,
        emailUpdates: existingSalesOrder.emailUpdates,
        specialDeliveryComment1: existingSalesOrder.specialDeliveryComment1,
        applianceRemoval: existingSalesOrder.applianceRemoval,
        distributorCode: existingSalesOrder.distributorCode,
        shipToState: existingSalesOrder.shipToState
      };
      var newObj = {
        deliveryOption: deliveryOption,
        shipToName: name,
        shipToAddress: address,
        shipToAddress2: address2,
        shipToPostcode: postCode,
        shipToCity: suburb,
        requestedDeliveryDate: deliveryDate,
        stairs: stairs,
        lift: lift,
        emailUpdates: warranty,
        specialDeliveryComment1: deliveryComment,
        applianceRemoval: removeAppliance,
        distributorCode: distributorCode,
        shipToState: state,
      };

      console.log(compareObj);
      console.log(newObj);
      if (JSON.stringify(compareObj) === JSON.stringify(newObj)) {
        handleNext();
      } else {
        axios.patch(`${ServerURL}/sales-orders/update-order/${existingSalesOrder.id}`, newObj, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          if (res.status === 200) {
            if (removeAppliance !== removeApplianceBackup) {
              if (!removeAppliance) {
                var toDelete = existingSalesOrder.salesorderline.filter(so => so.No === '6112')[0];
                if (toDelete) {
                  axios.delete(`${ServerURL}/sales-orders/delete-item/${toDelete.id}`, {
                    headers: {
                      'Authorization': `Bearer ${authProvider.getToken()}`
                    }
                  }).then(res => {
                  }).catch(err => { console.log(err); setSaving(false) }).finally(() => handleNext());
                } else {
                  setSnackbar({
                    message: "Error when deleting removal fee",
                    severity: "error"
                  });
                  setSaving(false);
                }
              } else {
                axios.post(`${ServerURL}/sales-orders/add-item`, {
                  '@odata.etag': existingSalesOrder['@odata.etag'],
                  Document_Type: "Order",
                  Document_No: existingSalesOrder.number,
                  Type: 'G/L Account',
                  Description: 'Removal Fee',
                  No: '6112',
                  unitPrice: 45,
                  Quantity: 1
                }, {
                  headers: {
                    'Authorization': `Bearer ${authProvider.getToken()}`
                  }
                }).then(res => {
                }).catch(err => { console.log(err); setSaving(false) }).finally(() => handleNext());
              }
            } else {
              handleNext();
            }
          }
        }).catch(err => {
          console.log(JSON.stringify(err.response));
          setSnackbar({
            message: "Failed to update order",
            severity: "error"
          });
          setSaving(false);
        });
      }
    } else {
      if (!nameTouched) {
        setNameError(true);
        setNameErrMsg('Required field');
      }
      if (!deliveryDateTouched) {
        setDeliveryDateError(true);
        setDeliveryDateErrMsg('Required field');
      }
      if (!postCodeTouched) {
        setPostCodeError(true);
        setPostCodeErrMsg('Required field');
      }
      if (!addressTouched) {
        setAddressError(true);
        setAddressErrMsg('Required field');
      }
      if (!suburbTouched) {
        setSuburbError(true);
        setSuburbErrMsg('Required field');
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: isMobile ? 3 : 5,
        px: isMobile ? 1 : 5,
        mt: 5,
        backgroundColor: 'white'
      }}
    >
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h4"
      >
        Enter delivery details
      </Typography>
      <Grid item={true} xs={12} lg={6} style={{ marginTop: '20px' }}>
        <FormControl variant="outlined" fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
          <InputLabel id="deliveryOption" size="small">Delivery option</InputLabel>
          <Select
            id="deliveryOption"
            value={deliveryOption}
            onChange={handleChange}
            label="Delivery option"
            name="deliveryOption"
            size="small"
            disabled={saving || deliveryPostcodeVerifying}
          >
            {renderOptions}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Ship-To Full Name"
          margin="normal"
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={name}
          variant="outlined"
          size="small"
          error={nameError}
          helperText={nameError && nameErrMsg}
          disabled={saving || fieldDisable || deliveryPostcodeVerifying}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <TextField
          fullWidth
          label="Address"
          margin="normal"
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          inputRef={materialRef}
          type="text"
          value={address}
          variant="outlined"
          size="small"
          error={addressError}
          helperText={addressError && addressErrMsg}
          disabled={saving || fieldDisable || deliveryPostcodeVerifying}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <TextField
          fullWidth
          label="Address 2"
          margin="normal"
          name="address2"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={address2}
          variant="outlined"
          size="small"
          disabled={saving || fieldDisable || deliveryPostcodeVerifying}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <Grid container>
          <Grid xs={5}>
            <TextField
              fullWidth
              label="Postcode"
              margin="normal"
              name="postcode"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={postCode}
              variant="outlined"
              size="small"
              error={postCodeError}
              helperText={postCodeError && postCodeErrMsg}
              disabled={saving || fieldDisable || deliveryPostcodeVerifying}
            />
          </Grid>
          <Grid xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginTop: '10px' }}>
              {(deliveryOption === 'To Customer' && customerDelivery !== null) ? (customerDelivery ? <CorrectIcon size="20" color="green" /> : <WrongIcon size="20" color="red" />) : null}
            </div>
          </Grid>
          <Grid xs={6} style={{ alignItems: 'center', display: 'flex', marginTop: '16px', marginBottom: '8px', backgroundColor: deliveryOption === 'To Customer' ? (customerDelivery ? 'green' : 'red') : 'inherit', borderRadius: '4px' }}>
            <Typography
              color="textPrimary"
              variant="h5"
              guttertop={true}
              style={{ color: 'white', paddingLeft: 10 }}
            >
              {customerDelivery !== null ? (customerDelivery ? 'Customer delivery available' : 'Customer delivery unavailable') : null}
            </Typography>
          </Grid>
        </Grid>
        <FormControl variant="outlined" fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
          <InputLabel id="suburb" size="small">Suburb</InputLabel>
          <Select
            id="suburb"
            value={suburb}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Suburb"
            name="suburb"
            size="small"
            error={suburbError}
            disabled={saving || fieldDisable || deliveryPostcodeVerifying}
          >
            {suburbLoading && (
              <MenuItem>
                <CircularProgress />
              </MenuItem>
            )}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {renderSuburbs}
          </Select>
        </FormControl>
        {suburbError && (
          <p style={{
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            textAlign: 'left',
            marginTop: '4px',
            marginRight: '14px',
            marginBottom: 0,
            marginLeft: '14px',
            color: '#d32f2f'
          }}>{suburbErrMsg}</p>
        )}
        <FormControlLabel
          control={
            <SingleDatePicker
              date={moment(deliveryDate, 'YYYY-MM-DD')} // momentPropTypes.momentObj or null
              onDateChange={date => setDeliveryDate(date.format('YYYY-MM-DD'))} // PropTypes.func.isRequired
              focused={calendarFocus} // PropTypes.bool
              onFocusChange={({ focused }) => { setCalendarFocus(focused) }} // PropTypes.func.isRequired
              id="delivery-date" // PropTypes.string.isRequired,
              numberOfMonths={1}
              readOnly
              isDayBlocked={
                momentDate => momentDate.format('ddd') === 'Sat' || momentDate.format('ddd') === 'Sun' || fixedHolidays.includes(momentDate.format('DD-MM')) || dynamicHolidays.includes(momentDate.format('DD-MM-YYYY'))
              }
              displayFormat="DD/MM/YYYY"
            />
          }
          label="Preferred delivery date"
          labelPlacement="start"
          disabled={saving || deliveryPostcodeVerifying}
        />
        {deliveryOption === 'To Customer' && (
          <div>
            <FormControlLabel
              value="removeAppliance"
              control={<Checkbox color="primary" checked={removeAppliance} onChange={handleChange} name="removeAppliance" />}
              label="Remove old appliance (Additional AU$45.00)"
              labelPlacement="start"
              disabled={saving || deliveryPostcodeVerifying}
            />
          </div>
        )
        }
        <div>
          <FormControlLabel
            value="stairs"
            control={<Checkbox color="primary" checked={stairs} onChange={handleChange} name="stairs" />}
            label="Stairs?"
            labelPlacement="start"
            disabled={saving || deliveryPostcodeVerifying}
          />
          <FormControlLabel
            value="lift"
            control={<Checkbox color="primary" checked={lift} onChange={handleChange} name="lift" />}
            label="Lift?"
            labelPlacement="start"
            disabled={saving || deliveryPostcodeVerifying}
          />
        </div>
        <div>
          <FormControlLabel
            value="warranty"
            control={<Checkbox color="primary" checked={warranty} onChange={handleChange} name="warranty" />}
            label="Warranty and product updates via email"
            labelPlacement="start"
            disabled={saving || deliveryPostcodeVerifying}
          />
        </div>
        <TextField
          fullWidth
          label="Special delivery comments"
          margin="normal"
          name="deliveryComment"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={deliveryComment}
          variant="outlined"
          size="small"
          disabled={saving || deliveryPostcodeVerifying}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Error
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
            No / insufficient delivery details found for {deliveryOption}. Please contact support.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ width: '100%' }}
            onClick={() => {
              handleDeliveryOptionChange('To Customer');
              setOpen(false);
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleBack} size="large" disabled={saving || deliveryPostcodeVerifying}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          size="large"
          disabled={saving || deliveryPostcodeVerifying || customerDelivery === false}
        >
          Next
        </Button>
        {(saving || deliveryPostcodeVerifying) && <CircularProgress style={{ marginLeft: '10px' }} />}
      </div>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
};

export default DeliveryDetails;
