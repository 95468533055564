import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Button,
  InputAdornment,
  SvgIcon,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';

const axios = require('axios');

const PendingOrdersListResults = () => {

  const navigate = useNavigate();
  const [nextLink, setNextLink] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [salesPersonLoading, setSalesPersonLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('number'); //displayName, postalCode, phoneNumber, number
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState('All');
  const [snackbar, setSnackbar] = useState({});
  const isMobile = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    axios.post(`${ServerURL}/sales-orders/search`, {
      filter: filter,
      keyword: search.toUpperCase(),
      salesPerson: selectedSalesPerson
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.data.error) {
        setPendingOrders([]);
        setSnackbar({
          message: "Something went wrong. Please try again.",
          severity: "error"
        });
      } else {
        setPendingOrders(res.data.value);
        if (res.data['@odata.nextLink']) {
          setNextLink(res.data['@odata.nextLink']);
        } else {
          setNextLink(null);
        }
      }
      setSearch('');
      setFilter('number');
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: "Failed to fetch pending orders.",
        severity: "error"
      });
    }).finally(() => setOrdersLoading(false));
  }, [selectedSalesPerson]);

  useEffect(() => {
    axios.get(`${ServerURL}/sales-persons`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setSalesPersonList([{ Code: 'All', Name: 'All Salesperson' }, ...res.data.value]);
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: "Failed to fetch sales persons",
        severity: "error"
      });
      setSalesPersonLoading(false);
    }).finally(() => setSalesPersonLoading(false));
  }, []);

  const handleClose = () => {
    setSnackbar({})
  }

  const searchOrder = () => {
    setOrdersLoading(true);
    setNextLink(null);
    axios.post(`${ServerURL}/sales-orders/search`, {
      filter: filter,
      keyword: search.toUpperCase(),
      salesPerson: selectedSalesPerson
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.data.error) {
        setSnackbar({
          message: 'Something went wrong. Please try again',
          severity: "error"
        });
        setPendingOrders(new Array());
      } else {
        setPendingOrders(res.data.value);
        if (res.data['@odata.nextLink']) {
          setNextLink(res.data['@odata.nextLink']);
        }
      }
    }).catch(err => setPendingOrders([])).finally(() => setOrdersLoading(false));
  }

  const loadMoreOrders = () => {
    setLoadMore(true);
    axios.post(`${ServerURL}/sales-orders/load-more`, {
      link: nextLink
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      var newArr = pendingOrders.concat(res.data.value);
      setPendingOrders(newArr);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      } else {
        setNextLink(null);
      }
    }).catch(err => console.log(err.response)).finally(() => setLoadMore(false));
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Card>
          <CardContent style={isMobile ? { textAlign: 'center' } : { display: 'flex', alignItems: 'center' }}>
            <FormControl variant="outlined" style={isMobile ? { width: '100%', paddingBottom: '20px' } : { width: '200px', marginRight: '30px' }} size={isMobile ? 'small' : 'large'}>
              <Select
                id="select-salesperson"
                value={selectedSalesPerson}
                onChange={(e) => setSelectedSalesPerson(e.target.value)}
                name="select-salesperson"
              >
                {salesPersonLoading && (
                  <MenuItem style={{ justifyContent: 'center' }}>
                    <CircularProgress />
                  </MenuItem>
                )}
                {
                  salesPersonList.map((s, i) => (
                    <MenuItem key={s.Code} value={s.Code}>
                      {s.Name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={isMobile ? { width: '100%' } : { width: '200px', marginRight: '30px' }} size={isMobile ? 'small' : 'large'}>
              <Select
                id="search-filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                name="search-filter"
              >
                <MenuItem value="number">
                  Web Order No.
                </MenuItem>
                <MenuItem value="customerName">
                  Name
                </MenuItem>
                <MenuItem value="postalCode">
                  Postcode
                </MenuItem>
                <MenuItem value="phoneNumber">
                  Phone number
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={isMobile ? { width: '100%', pt: 2, pb: 2 } : { maxWidth: 500, width: 500, pr: 3 }}>
              <TextField
                fullWidth
                size={isMobile ? 'small' : 'large'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchOrder();
                  }
                }}
              />
            </Box>
            <Button
              color="primary"
              variant="contained"
              size={isMobile ? 'small' : 'large'}
              onClick={() => searchOrder()}
            >
              Search
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Card style={{ marginBottom: '50px' }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Customer Name
                  </TableCell>
                  <TableCell>
                    Number
                  </TableCell>
                  <TableCell>
                    Salesperson
                  </TableCell>
                  <TableCell>
                    Order date
                  </TableCell>
                  <TableCell>
                    Phone Number
                  </TableCell>
                  <TableCell>
                    Total amount
                  </TableCell>
                  <TableCell>
                    Paid amount
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ordersLoading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (pendingOrders && pendingOrders.map((order) => (
                  <TableRow
                    hover
                    key={order.number}
                  >
                    <TableCell>
                      {order.customerName}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          <Button onClick={() => navigate(`/app/new-order/${order.number}`, { state: { totalPaidAmount: (order.totalAmountIncludingTax - order.remainingamt) } })}>{order.number}</Button>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {order.salesperson?.Name}
                    </TableCell>
                    <TableCell>
                      {moment(order.orderDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>
                      {order.phoneNumber}
                    </TableCell>
                    <TableCell>
                      AU${order.totalAmountIncludingTax.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      AU${(order.totalAmountIncludingTax - order.remainingamt).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {order.status}
                    </TableCell>
                  </TableRow>
                )))}
                {nextLink && (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                      {loadMore ? (
                        <LinearProgress />
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => loadMoreOrders()}
                        >
                          Load more
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PendingOrdersListResults;
