import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@material-ui/core';

const ReviewItems = ({handleNext, handleBack, removeItem, updateQuantity, existingSalesOrder, salesOrderUpdating, isMobile}) => {

  const renderCartItems = existingSalesOrder.salesorderline.map((c,i) => (
    <TableRow key={c.id}>
      <TableCell component="th" scope="row" style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
        {c.No}
      </TableCell>
      <TableCell style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
        {c.No.length ? (
        <TextField
        size="small"
        value={c.Quantity}
        type="number"
        onChange={(e) => updateQuantity(c, e.target.value)}
        disabled={salesOrderUpdating}
        inputProps={{
          min: 1,
          max: 100,
          step: 1,
          onKeyDown: (event) => {
             event.preventDefault();
          },
        }}/>) : null }
      </TableCell>
      <TableCell style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
        {c.Description}
      </TableCell>
      <TableCell style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
        {c.No.length ? (`AU${c.unitPrice.toFixed(2)}`) : null}
      </TableCell>
      <TableCell style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
      {c.No.length ? (`AU${c.lineAmount.toFixed(2)}`) : null}
      </TableCell>
      <TableCell align="right" style={c.No.length ? {} : {paddingTop: 5, paddingBottom: 5}}>
      {c.No.length ? (
        <Button
        variant="contained"
        color="primary"
        size="size"
        style={{background: 'red'}}
        onClick={() => removeItem(c)}
        disabled={salesOrderUpdating}
      >
        Remove
      </Button>
      ) : null}
      </TableCell>
    </TableRow>
  ));

  return(
    <Box
      sx={{
        minHeight: '100%',
        py: isMobile ? 3 : 5,
        px: isMobile ? 1 : 5,
        mt: 5,
        backgroundColor: 'white'
      }}
    >
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h4"
        mb={5}
      >
        Please review the items below and update the quantity as required. Items are inclusive of GST.
      </Typography>
      <TableContainer component={Paper}>
        <Table style={{minWidth: '560px'}} aria-label="review-items">
          <TableHead>
            <TableRow>
              <TableCell>Item Number</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Line Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCartItems}
            <TableRow key={999}>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell>{existingSalesOrder.totalQuantity}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>AU${existingSalesOrder.totalAmountIncludingTax.toFixed(2)}</TableCell>
              <TableCell align="right">
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{textAlign: 'end', marginTop: '20px'}}>
        <Button onClick={handleBack} size="large" disabled={salesOrderUpdating}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          size="large"
          disabled={salesOrderUpdating}
        >
          Next
        </Button>
      </div>
    </Box>
  )
};

export default ReviewItems;
