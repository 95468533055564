import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Modal,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';
import InputMask from 'react-input-mask';

const axios = require('axios');
const valid = require('card-validator');
var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
};

const PaymentInformation = ({ handleNext, handleBack, existingSalesOrder, salesOrderUpdating, isMobile, paidAmount, updateSalesOrder }) => {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountToPay, setAmountToPay] = useState((existingSalesOrder.totalAmountIncludingTax - existingSalesOrder.remainingamt).toFixed(2));
  const [creditCardType, setCreditCardType] = useState(1);
  const [storeType, setStoreType] = useState(existingSalesOrder?.storeType ? existingSalesOrder.storeType : 'CASH');
  const [storeReceipt, setStoreReceipt] = useState(existingSalesOrder.storeReceipt ? existingSalesOrder.storeReceipt : '');

  const [paymentType, setPaymentType] = useState(null); // 'full' or 'partial'
  const [insertEmailModalOpen, setInsertEmailModalOpen] = useState(false);
  const [emailSaving, setEmailSaving] = useState(false);

  const [cardholderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');

  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [postingSuccess, setPostingSuccess] = useState(null);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const [cardType, setCardType] = useState('placeholder');

  const [saving, setSaving] = useState(false);

  const [amountToPayError, setAmountToPayError] = useState(false);
  const [snackbar, setSnackbar] = useState({});

  const emailRef = useRef();

  const handleClose = () => {
    setSnackbar({})
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'paymentMethod':
        setPaymentMethod(e.target.value);
        break;
      case 'storePaymentMethod':
        setStoreType(e.target.value);
        break;
      case 'storeReceipt':
        if (e.target.value.length <= 20) {
          setStoreReceipt(e.target.value);
        }
        break;
      case 'cardholdername':
        setCardHolderName(e.target.value);
        break;
      case 'cardnumber':
        var result = valid.number(cardNumber);
        setCardType(result.card ? (result.card.type === 'visa' ? 'visa' : (result.card.type === 'mastercard' ? 'mastercard' : 'placeholder')) : 'placeholder');
        setCardNumber(e.target.value);
        break;
      case 'cvv':
        setCvv(e.target.value);
        break;
      case 'expirydate':
        setExpiryDate(e.target.value);
        break;
    }
  };

  useEffect(() => {
    setAmountPaid(existingSalesOrder.totalAmountIncludingTax - existingSalesOrder.remainingamt);
    setAmountToPay(existingSalesOrder.remainingamt);
  }, [existingSalesOrder]);

  const checkForEmailInSalesOrder = () => {
    if (!existingSalesOrder.email || existingSalesOrder.length === 0) {
      setInsertEmailModalOpen(true);
      return false;
    }

    return true;
  };

  const processPayment = async () => {
    //Check if sales order has an email, otherwise prompt for them to insert it.
    var isEmailFound = checkForEmailInSalesOrder();
    if (!isEmailFound) return;
    setSaving(true);
    if (paymentMethod === 'creditCard') {
      if (cardholderName?.length && cardNumber?.length && expiryDate?.length && cvv?.length) {
        // if(true) {
        var result = valid.number(cardNumber);
        if (result.isValid) {
          // if(true) {
          if (result.card.type === 'mastercard' || result.card.type === 'visa') {
            setPostingSuccess(false);
            setPaymentSuccess(false);
            // if(true) {
            setPaymentLoading(true);
            var splitDate = expiryDate.split('/');
            var paymentObj = {
              cardholderName: cardholderName,
              cardNumber: cardNumber,
              expiryDateMonth: splitDate[0],
              expiryDateYear: splitDate[1],
              cvn: cvv,
              paymentMethod: 'creditCard'
            };
            var requestBody = {
              creditCard: paymentObj,
              amountToPay: amountToPay,
              remainingAmount: existingSalesOrder.remainingamt,
              orderNumber: existingSalesOrder.number,
              customerNumber: existingSalesOrder.SellltocustomerNo,
              customerName: existingSalesOrder.customerName,
              salesOrderId: existingSalesOrder.id
            };
            try {
              const cardPaymentResult = await axios.post(`${ServerURL}/payments/charge/v2`, requestBody, {
                headers: {
                  'Authorization': `Bearer ${authProvider.getToken()}`
                }
              });
              if (cardPaymentResult.status === 200) {
                setPaymentSuccess(true);
                //Post sales order or prepayment now
                var amountPaid = cardPaymentResult.data.data.amountPaid;
                var remainingAmount = cardPaymentResult.data.data.remainingAmount;
                if (amountPaid === remainingAmount) {
                  //Post sales order
                  const salesOrderPostResult = await axios.post(`${ServerURL}/payments/full-payment`, {
                    orderNumber: existingSalesOrder.number,
                    salesOrderId: existingSalesOrder.id
                  }, {
                    headers: {
                      'Authorization': `Bearer ${authProvider.getToken()}`
                    },
                    timeout: 120000, // 120 seconds
                  });
                  
                  if (salesOrderPostResult.status === 200) {
                    setPostingSuccess(true);
                    setPaymentType('full');
                    setPaymentLoading(false);
                    setOpen(true);
                  }
                } else {
                  //Post prepayment
                  const salesOrderPrepaymentResult = await axios.post(`${ServerURL}/payments/partial-payment`, {
                    orderNumber: existingSalesOrder.number,
                    salesOrderId: existingSalesOrder.id,
                    amountToPay: amountPaid
                  }, {
                    headers: {
                      'Authorization': `Bearer ${authProvider.getToken()}`
                    }
                  });
                  if (salesOrderPrepaymentResult.status === 200) {
                    setPostingSuccess(true);
                    setPaymentType('partial');
                    setPaymentLoading(false);
                    setOpen(true);
                  }
                }
              }
            } catch (err) {
              setErrorOpen(true);
              setSnackbar({
                message: err?.response?.data?.message || err?.response?.data?.error?.message || "Failed to make payment",
                severity: "error"
              });
              console.log(err?.response?.data?.message)
              setSaving(false);
              setPaymentLoading(false);
            }
          } else {
            setSnackbar({
              message: 'Only Visa and MasterCard is accepted',
              severity: "warning"
            });
            setSaving(false);
          }
        } else {
          setSnackbar({
            message: 'Please enter a valid card',
            severity: "warning"
          });
          setSaving(false);
        }
      } else {
        setSnackbar({
          message: 'Please fill in card details',
          severity: "warning"
        });
        setSaving(false);
      }
    } else {
      if (storeReceipt) {
        setPaymentLoading(true);
        var updateObj = {
          storePaymentMethod: storeType,
          storeReceipt: storeReceipt,
          remainingamt: existingSalesOrder.remainingamt,
          PaymentMethodCode: 'STORE',
          amountToPay: amountToPay,
          salesOrderId: existingSalesOrder.id
        };
        axios.patch(`${ServerURL}/sales-orders/update-payment/${existingSalesOrder.number}`, updateObj, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          if (res.status === 200) {
            setPaymentType(res.data.type);
            setOpen(true);
          }
          setPaymentLoading(false);
        }).catch(err => {
          console.log(err.response.data.error);
          setPaymentLoading(false);
          setSaving(false);
          setSnackbar({
            message: err?.response?.data?.error?.message || "Failed to make payment",
            severity: "error"
          });
        });
      } else {
        setSnackbar({
          message: 'Please enter store receipt',
          severity: "warning"
        });
        setSaving(false);
      }
    }
  };

  const renderCartItems = existingSalesOrder.salesorderline.map((so, i) => (
    <TableRow key={so.id}>
      <TableCell component="th" scope="row" style={so.No.length ? {} : { paddingTop: 5, paddingBottom: 5 }}>
        {so.No}
      </TableCell>
      <TableCell style={{ width: '100px' }}>
        {so.No.length ? so.Quantity : null}
      </TableCell>
      <TableCell style={so.No.length ? {} : { paddingTop: 5, paddingBottom: 5 }}>
        {so.Description}
        {so.ExtendedTxt.split(';').map((t, i) => {
          if (t.length) {
            return (
              <small style={{ display: 'block', marginTop: '5px' }} key={i}>- {t}</small>
            )
          }
        })}
      </TableCell>
      <TableCell style={so.No.length ? {} : { paddingTop: 5, paddingBottom: 5 }}>
        {so.No.length ? (`AU$${so.unitPrice.toFixed(2)}`) : null}</TableCell>
      <TableCell align="right" style={so.No.length ? {} : { paddingTop: 5, paddingBottom: 5 }}>
        {so.No.length ? (`AU$${(so.unitPrice * so.Quantity).toFixed(2)}`) : null}
      </TableCell>
    </TableRow>
  ));

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: isMobile ? 3 : 5,
        px: isMobile ? 1 : 5,
        mt: 5,
        backgroundColor: 'white'
      }}
    >
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h4"
      >
        Review your order
      </Typography>
      {salesOrderUpdating ? (
        <LinearProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table style={{ minWidth: '560px' }} aria-label="review-items">
              <TableHead>
                <TableRow>
                  <TableCell>Item Number</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell align="right">Line Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderCartItems}
                <TableRow>
                  <TableCell component="th" scope="row">
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><strong>Sub Total</strong></TableCell>
                  <TableCell align="right"><strong>AU${existingSalesOrder.totalAmountExcludingTax?.toFixed(2)}</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><strong>GST</strong></TableCell>
                  <TableCell align="right"><strong>AU${(existingSalesOrder.totalAmountIncludingTax - existingSalesOrder.totalAmountExcludingTax).toFixed(2)}</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><strong>Grand Total</strong></TableCell>
                  <TableCell align="right"><strong>AU${existingSalesOrder.totalAmountIncludingTax.toFixed(2)}</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><strong>Total Amount Paid</strong></TableCell>
                  <TableCell align="right"><strong>AU${(existingSalesOrder.totalAmountIncludingTax - existingSalesOrder.remainingamt).toFixed(2)}</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><strong>Remaining amount</strong></TableCell>
                  <TableCell align="right"><strong>AU${(existingSalesOrder.totalAmountIncludingTax - amountPaid).toFixed(2)}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
          >
            <Grid item xs={12} lg={6} py={5} style={{ paddingRight: '40px' }}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Payment detail
              </Typography>
              <TableContainer style={{ marginTop: '20px' }}>
                <Table aria-label="review-items">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: '100px' }}>Payment method: </TableCell>
                      <TableCell align="left">
                        <RadioGroup row name="paymentMethod" defaultValue="creditCard" disabled={saving}>
                          <FormControlLabel
                            value="creditCard"
                            control={<Radio color="primary" />}
                            label="Credit Card"
                            labelPlacement="end"
                            onChange={handleChange}
                          />
                          <FormControlLabel
                            value="store"
                            control={<Radio color="primary" />}
                            label="Store"
                            labelPlacement="end"
                            onChange={handleChange}
                          />
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ maxWidth: '100px' }}>Amount to pay: </TableCell>
                      <TableCell align="left">
                        <TextField size="small" type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">AU$</InputAdornment>,
                          }}
                          value={amountToPay}
                          onChange={(e) => {
                            const inputVal = parseFloat(e.target.value);
                            setAmountToPay(e.target.value);
                            if (isNaN(inputVal) || inputVal < 0.01) {
                              setAmountToPayError(true)
                            } else {
                              setAmountToPayError(false);
                            }
                          }}
                          disabled={saving}
                          error={amountToPayError}
                          helperText={amountToPayError ? "Amount must be greater than 0.01" : ""}
                          inputProps={{
                            min: 0.01,
                            step: "any"
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ maxWidth: '100px' }}>Remaining amount: </TableCell>
                      <TableCell align="left">AU${(existingSalesOrder.totalAmountIncludingTax - amountPaid).toFixed(2)}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={6} py={5}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {paymentMethod === 'creditCard' ? 'Card details' : 'Store details'}
              </Typography>
              {
                paymentMethod === 'creditCard' ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField size="small" type="text"
                        fullWidth
                        margin="normal"
                        value={cardholderName}
                        onChange={handleChange}
                        variant="outlined"
                        name="cardholdername"
                        label="Cardholder Name"
                        disabled={saving}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputMask mask="9999 9999 9999 9999" maskChar="" value={cardNumber} onChange={handleChange} name="cardnumber" disabled={saving}>
                        {inputProps =>
                          <TextField
                            {...inputProps}
                            fullWidth
                            type="text"
                            margin="normal"
                            variant="outlined"
                            label="Card Number"
                            placeholder="•••• •••• •••• ••••"
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end"><img width="30px" src={`/static/images/icons/${cardType}.png`} /></InputAdornment>,
                            }}
                          />
                        }
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputMask mask="99/99" maskChar="" value={expiryDate} onChange={handleChange} name="expirydate" disabled={saving}>
                        {inputProps =>
                          <TextField
                            {...inputProps}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Expiry Date"
                            placeholder="MM/YY"
                            size="small"
                            type="text"
                          />
                        }
                      </InputMask>
                    </Grid>
                    <Grid item xs={0} md={2} />
                    <Grid item xs={12} md={5}>
                      <InputMask mask="999" maskChar="" value={cvv} onChange={handleChange} name="cvv" disabled={saving}>
                        {inputProps =>
                          <TextField
                            {...inputProps}
                            size="small"
                            type="text"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Security Code"
                          />
                        }
                      </InputMask>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <FormControl variant="outlined" fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
                      <InputLabel id="sales-person" size="small">Store payment method</InputLabel>
                      <Select
                        id="store-payment-method"
                        value={storeType}
                        onChange={handleChange}
                        label="Store payment method"
                        name="storePaymentMethod"
                        size="small"
                        disabled={saving}
                      >
                        {storePaymenOptions.map((s, i) => (
                          <MenuItem value={s} key={i}>{s}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField size="small" type="text"
                      fullWidth
                      margin="normal"
                      value={storeReceipt}
                      onChange={handleChange}
                      name="storeReceipt"
                      variant="outlined"
                      label="Store receipt approval no."
                      disabled={saving}
                    />
                  </>
                )
              }
            </Grid>
          </Grid>

          {paymentLoading && (<Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div style={{ textAlign: 'center' }}>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                  <CircularProgress />
                </Typography>
                <Typography id="modal-modal-title" variant="h3" component="h2">
                  Processing payment...
                </Typography>
                <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2, mb: 3 }}>
                  Credit card charge: {paymentSuccess !== null ? (paymentSuccess ? 'Success' : 'Failed') : 'Waiting...'}
                </Typography>
                <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2, mb: 3 }}>
                  Sales order posting: {postingSuccess !== null ? (postingSuccess ? 'Success' : 'Failed') : 'Waiting...'}
                </Typography>
              </div>
            </Box>
          </Modal>
          )}

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Success!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                Thank you! Your payment has been accepted. Please inform the customer that Speed Queen will contact the customer to confirm delivery details.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (paymentType === 'partial') {
                    navigate(`/app/pending-orders`);
                  } else {
                    navigate(`/app/transaction-history`);
                  }
                }}
                size="large"
                style={{ width: '100%' }}
              >
                OK
              </Button>
            </Box>
          </Modal>

          <Modal
            open={errorOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Oops! Something went wrong. Please contact sales.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 3, mb: 3 }}>
                Credit card charge: <Typography variant="h5" component="h5" style={{ color: paymentSuccess ? 'green' : 'red' }}>{paymentSuccess ? 'success' : 'failed'}</Typography>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 3, mb: 3 }}>
                Posting sales order: <Typography variant="h5" component="h5" style={{ color: postingSuccess ? 'green' : 'red' }}>{postingSuccess ? 'success' : 'failed'}</Typography>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setErrorOpen(false);
                  setPaymentSuccess(null);
                  setPostingSuccess(null);
                }}
                size="large"
                style={{ width: '100%' }}
              >
                OK
              </Button>
            </Box>
          </Modal>

          <Modal
            open={insertEmailModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Email not found.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Please enter email to receive your invoice.
              </Typography>
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                size="small"
                inputRef={emailRef}
                disabled={emailSaving}
              />
              <div style={{ marginTop: '20px' }} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!emailReg.test(emailRef.current.value)) {
                    setSnackbar({
                      message: 'Email is not valid',
                      severity: "warning"
                    });
                    return;
                  }
                  setEmailSaving(true);
                  axios.patch(`${ServerURL}/sales-orders/update-order/${existingSalesOrder.id}`, {
                    email: emailRef.current.value
                  }, {
                    headers: {
                      'Authorization': `Bearer ${authProvider.getToken()}`
                    }
                  }).then(res => {
                    if (!res.data.error) {
                      setSnackbar({
                        message: 'Successfully saved email',
                        severity: "success"
                      });
                      setInsertEmailModalOpen(false);
                      updateSalesOrder(existingSalesOrder.id);
                    }
                  }).catch(err => {
                    console.log(err);
                    setSnackbar({
                      message: 'Something went wrong',
                      severity: "error"
                    });
                  }).finally(() => setEmailSaving(false));
                }}
                size="large"
                style={{ width: '100%' }}
                disabled={emailSaving}
              >
                SAVE EMAIL
              </Button>
              <div style={{ marginTop: '10px' }} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setInsertEmailModalOpen(false);
                }}
                size="large"
                style={{ width: '100%' }}
                disabled={emailSaving}
              >
                CANCEL
              </Button>
            </Box>
          </Modal>
        </>
      )}
      <div style={{ textAlign: 'end', marginTop: '20px' }}>
        {(paidAmount && paidAmount > 0) ?
          null
          : (
            <Button onClick={handleBack} size="large" disabled={salesOrderUpdating || saving}>
              Back
            </Button>
          )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => processPayment()}
          size="large"
          disabled={salesOrderUpdating || saving || amountToPay < 0.01}
        >
          Next
        </Button>
      </div>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const creditCardPaymentOptions = [
  {
    value: 1,
    name: 'VISA'
  },
  {
    value: 2,
    name: 'MASTER CARD'
  }
];

const storePaymenOptions = ['CASH', 'EFTPOS', 'GIFT VOUCHER', 'INTEREST FREE'];

export default PaymentInformation;
