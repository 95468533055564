export const links = [
  {
    id: 1,
    src: '/static/files/Speed_Queen_Price_List.pdf',
    title: 'Download Price List'
  },
  {
    id: 2,
    src: '/static/files/Speed_Queen_Range_Brochure.pdf',
    title: 'Download Brochure'
  }
];
