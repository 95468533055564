export const videoList = [
  {
    id: 1,
    src: '/static/videos/1_AWNA62_BLACK_Traditional.mp4',
    title: '1. AWNA62 BLACK Traditional Speed Queen 8.5kg Top Load'
  },
  {
    id: 2,
    src: '/static/videos/2_AWNE92_BLACK_Touch_Speed_Queen.mp4',
    title: '2. AWNE92 BLACK Touch Speed Queen 8.5kg Top Load'
  },
  {
    id: 3,
    src: '/static/videos/3_AFNE9B_AFNE9BSS_PLATINUM_Speed_Queen.mp4',
    title: '3. AFNE9B & AFNE9BSS PLATINUM Speed Queen 10KG Front Loads'
  },
  {
    id: 4,
    src: '/static/videos/4_ATE_G_STACK_Speed_Queen.mp4',
    title: '4. ATE G STACK Speed Queen'
  },
  {
    id: 5,
    src: '/static/videos/5_Speed_Queen_Dryer_Overview.mp4',
    title: '5. Speed Queen Dryer Overview'
  },
  {
    id: 6,
    src: '/static/videos/6_How_to_Enter_a_Speed_Queen_Sale.mp4',
    title: '6. How to Enter a Speed Queen Sale'
  },
  {
    id: 7,
    src: '/static/videos/7_Speed_Queen_Installations_4aug20.mp4',
    title: '7. Speed Queen Installations 4aug20'
  },
  {
    id: 8,
    src: '/static/videos/8_Speed_Queen_Website_Navigation_22Oct20_sd.mp4',
    title: '8. Speed Queen Website Navigation 22Oct20 sd'
  },
  {
    id: 9,
    src: '/static/videos/Who_is_Speed_QueenAmerican_Made.mp4',
    title: 'Who is Speed Queen - American Made'
  },
  {
    id: 10,
    src: '/static/videos/Why_our_machines_lastSpeed_Queen_Built_Better_to_Last_Longer.mp4',
    title: 'Why our machines last - Speed Queen Built Better to Last Longer'
  },
];
