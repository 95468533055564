import { Typography, Grid, Box } from '@material-ui/core';

const DashboardFooter = () => {
  return(
    <>
      <div>
        <Typography
          color="textPrimary"
          variant="body1"
          style={{fontSize: '0.8em'}}
        >
          &#169; 2021 1300Washer Pty Ltd. All rights reserved. ABN 25165902474
        </Typography>
      </div>
      {
      //   <div>
      //   <Typography
      //     color="textPrimary"
      //     variant="body1"
      //     style={{fontSize: '0.8em'}}
      //   >
      //   Powered by <a target="_blank" href="https://www.rightsizetechnology.com.au">Rightsize Technology</a>
      //   </Typography>
      // </div>
      }
    </>
  )
};

export default DashboardFooter;
