import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListItem
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Info as InfoIcon,
  Video as VideoIcon,
  FilePlus as FilePlusIcon,
  List as ListIcon,
  DollarSign as DollarSignIcon,
  Settings as SettingsIcon,
  Folder as FolderIcon,
  LogOut as LogOutIcon,
  Phone as PhoneIcon,
  Lock as LockIcon,
  Archive as ArchiveIcon

} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NavItem from './NavItem';


const user = {
  jobTitle: 'Sales Manager',
  name: 'John Doe'
};

const items = [
  {
    section: 'Orders',
    list: [
      {
        href: '/app/new-order',
        icon: FilePlusIcon,
        title: 'New Order'
      },
      {
        href: '/app/pending-orders',
        icon: ListIcon,
        title: 'Pending Orders'
      }
    ]
  },
  {
    section: 'Administration',
    list: [
      {
        href: '/app/customers',
        icon: UsersIcon,
        title: 'Customers'
      },
      {
        href: '/app/salesperson-report',
        icon: DollarSignIcon,
        title: 'Salesperson Report'
      },
      {
        href: '/app/item-sales-report',
        icon: BarChartIcon,
        title: 'Item Sales Report'
      },
      {
        href: '/app/account',
        icon: InfoIcon,
        title: 'Update Salesperson Info',
        type: 'user'
      },
      {
        href: '/app/update-store-details',
        icon: SettingsIcon,
        title: 'Update Store Details',
        type: 'store'
      },
    ]
  },
  {
    section: 'History',
    list: [
      {
        href: '/app/transaction-history',
        icon: FolderIcon,
        title: 'Transaction History'
      },
    ]
  },
  {
    section: 'Training',
    list: [
      {
        href: '/app/videos',
        icon: VideoIcon,
        title: 'Videos'
      }
    ]
  },
  {
    section: 'About us',
    list: [
      {
        href: '/app/contact-us',
        icon: PhoneIcon,
        title: 'Contact Us'
      },
      {
        href: '/app/policies',
        icon: LockIcon,
        title: 'Policies'
      }
      // {
      //   href: '/app/videos',
      //   icon: ArchiveIcon,
      //   title: 'Privacy Policy'
      // }
    ]
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const logout = () => {
    localStorage.removeItem('displayName');
    localStorage.removeItem('accessToken');
    navigate('/login');
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 1
        }}
      >
        <div style={{marginBottom: '10px'}}>
        </div>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{textAlign: 'center'}}
        >
          {localStorage.getItem('displayName')}
        </Typography>
      </Box>
      <Divider />
      <PerfectScrollbar>
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((s,i) => {
            return (
              <div key={i} style={{marginTop: i === 0 ? 0 : '18px'}}>
              <Typography
                color="textPrimary"
                variant="h5"
                style={{padding: '5px 8px'}}
              >
                {s.section}
              </Typography>
              {s.list.map((item, ii) => {
                if(item.type) {
                  return(
                    <Button
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'medium',
                      justifyContent: 'flex-start',
                      letterSpacing: 0,
                      py: 1,
                      textTransform: 'none',
                      width: '100%',
                      '& svg': {
                        mr: 1
                      }
                    }}
                    key={ii}>
                    {item.type === 'user' ? (
                      <>
                      <InfoIcon size="20"/>
                      <span>
                        <a href="mailto:info@1300washer.com.au?subject=Request to update user information" style={{color:'#6b778c'}}>{item.title}</a>
                      </span>
                      </>
                    ) : (
                      <>
                      <SettingsIcon size="20"/>
                      <span>
                        <a href="mailto:info@1300washer.com.au?subject=Request to update store details" style={{color:'#6b778c'}}>{item.title}</a>
                      </span>
                      </>
                    )}
                    </Button>
                  )
                } else {
                  return(
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                    />
                  );
                }
            })}
              </div>
          )})}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{p: 2}}>
        <ListItem
          disableGutters
          sx={{
            display: 'flex',
            py: 0
          }}
        >
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1,
              textTransform: 'none',
              width: '100%',
              '& svg': {
                mr: 1
              }
            }}
            onClick={() => logout()}
          >
              <LogOutIcon size="20" />
            <span>
              Logout
            </span>
          </Button>
        </ListItem>
      </Box>
      </PerfectScrollbar>

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
