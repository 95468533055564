import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Alert, Snackbar
} from '@material-ui/core';
import Logo from '../components/Logo';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ServerURL } from '../theme/api';
import { links } from '../constants/loginPageLinks';
const axios = require('axios');

const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({});

  const handleClose = () => {
    setSnackbar({})
  }

  return (
    <>
      <Helmet>
        <title>1300Washer | Login</title>
      </Helmet>
      <div style={{
        backgroundImage: 'url(/static/images/1300washer-collage.jpg)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          backgroundImage: 'url(/static/images/1300washer-login-background.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Container maxWidth="md" style={{backgroundColor: 'white', borderRadius: '10px', paddingTop: '24px', paddingBottom: '24px'}}>
          <Grid container>
            <Grid item xs={12} lg={6}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Logo style={{width: '200px', height: '75px'}}/>
            </div>
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().max(255).required('username is required'),
                password: Yup.string().max(255).required('Password is required')
              })}
              onSubmit={({username, password}) => {
                setLoading(true);
                axios.post(`${ServerURL}/auth/login`, {
                  username: username,
                  password: password
                }).then(res => {
                  if(res.data && !res.data.error) {
                    localStorage.setItem('accessToken', res.data.accessToken);
                    localStorage.setItem('displayName', res.data.displayName);
                    navigate('/app/new-order', { replace: true });
                  } else {
                    setLoading(false);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('displayName');
                    setSnackbar({
                      message: res.data?.error?.message || "Failed to login",
                      severity: "error"
                    })
                  }
                }).catch(err => {
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('displayName');
                  setLoading(false)
                  setSnackbar({
                    message: err?.response?.data?.error?.message || err?.message || "Failed to login",
                    severity: "error"
                  })
                });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="Username"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={loading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign in
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            </Grid>
            <Grid item xs={12} lg={6} style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'inline-grid'}}>
              {links.map(l => (
                <>
                <Button
                 variant="contained"
                 color="primary"
                 startIcon={<PictureAsPdfIcon />}
                >
                 <a style={{color: 'white'}} href={l.src} target="_blank">{l.title}</a>
                </Button>
                <div style={{marginBottom: '20px'}}></div>
                </>
              ))}
            </div>
            </Grid>
          </Grid>
          <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={snackbar?.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              { snackbar?.message }
            </Alert>
          </Snackbar>
        </Container>
      </Box>
      </div>
    </>
  );
};

export default Login;
