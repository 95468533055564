import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Stepper, Box, Step, StepButton, Button, Typography, Card, CardContent, Grid, useMediaQuery, Snackbar, Alert
} from '@material-ui/core';
import SelectItems from '../components/order/SelectItems';
import CustomerInformation from '../components/order/CustomerInformation';
import ReviewItems from '../components/order/ReviewItems';
import DeliveryDetails from '../components/order/DeliveryDetails';
import PaymentInformation from '../components/order/PaymentInformation';
import { ServerURL } from '../theme/api';
import authProvider from '../authProvider';

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '24px'
  },
  mobileRoot: {
    width: '100%',
    padding: '10px'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select items', 'Customer information', 'Review items', 'Delivery details', 'Payment information'];
}

const NewOrder = () => {
  const classes = useStyles();
  const { orderId } = useParams();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(orderId ? (state.totalPaidAmount > 0 ? 4 : 2) : 0);
  const [salesOrderUpdating, setSalesOrderUpdating] = useState(false);

  const [user, setUser] = useState(null);
  const [salesPerson, setSalesPerson] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [itemLinks, setItemLinks] = useState([]);
  const [salesOrder, setSalesOrder] = useState(
    {
      id: null,
      number: null,
      salesorderline: [],
      totalAmountExcludingTax: 0,
      totalAmountIncludingTax: 0
    }
  );

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [snackbar, setSnackbar] = useState({});
  const isMobile = useMediaQuery("(max-width:480px)");
  const steps = getSteps();

  useEffect(() => {
    axios.get(`${ServerURL}/items/categories`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setCategories(res.data.value);
      axios.get(`${ServerURL}/items/${res.data.value[0].id}`, {
        headers: {
          'Authorization': `Bearer ${authProvider.getToken()}`
        }
      }).then(resp => {
        if (resp.status === 200) {
          if (!resp.data.value) {
            setSnackbar({
              message: `Something went wrong when getting items for category ${res.data.value[0].Code}`,
              severity: "error"
            });
          }
          setItemLinks(resp.data?.itemlinks?.length ? resp.data.itemlinks : []);
          setProducts(resp.data.value ? resp.data.value : []);
        } else {
          setSnackbar({
            message: `Oops! Something went wrong when getting items for category ${res.data.value[0].Code}`,
            severity: "error"
          });
        }
      }).catch(err => {
        console.log(err.response);
        setSnackbar({
          message: `Oops! Something went wrong when getting items for category ${res.data.value[0].Code}. Please contact administrator.`,
          severity: "error"
        });
      });
    }).catch(err => {
      console.log(err.response);
      setSnackbar({
        message: `Oops! Something went wrong when getting washer types. Please contact administrator.`,
        severity: "error"
      });
    });
  }, []);

  useEffect(() => {
    if (orderId) {
      setSalesOrderUpdating(true);
      axios.get(`${ServerURL}/sales-orders/get-by-order-number/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${authProvider.getToken()}`
        }
      }).then(res => {
        if (res.status === 200) {
          setSalesOrder(res.data.value[0]);
          setUser(res.data.value[0].customer);
          setSalesPerson(res.data.value[0].salespersonCode);
        };
      }).catch(err => {
        setSnackbar({
          message: `Failed to fetch order with ID ${orderId}`,
          severity: "error"
        });
        console.log(err.response);
        setSalesOrderUpdating(false) 
      }).finally(() => setSalesOrderUpdating(false));
    }
  }, []);

  const handleClose = () => {
    setSnackbar({})
  }

  const changeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
    var cat = categories[newValue];
    axios.get(`${ServerURL}/items/${cat.id}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.status === 200) {
        setItemLinks(res.data?.itemlinks?.length ? res.data.itemlinks : []);
        setProducts(res.data.value);
      };
    }).catch(err => {
      console.log(err.response);
      setSnackbar({
        message: `Failed to fetch category with ID ${cat.id}`,
        severity: "error"
      });
    });
  };

  const saveCustomer = (cust) => {
    setUser(new Object(cust));
  };

  const saveSalesPerson = (salesPersonCode) => {
    setSalesPerson(salesPersonCode);
  };

  const saveSalesOrder = (so) => {
    setSalesOrder(so);
  };

  const updateSalesOrder = (inId) => {
    if (inId) {
      axios.get(`${ServerURL}/sales-orders/${inId}`, {
        headers: {
          'Authorization': `Bearer ${authProvider.getToken()}`
        }
      }).then(res => {
        setSalesOrder(res.data.value[0]);
      }).catch(err => console.log(err)).finally(() => setSalesOrderUpdating(false));
    }
  };

  const addItem = (product) => {
    if (salesOrder.id) {
      setSalesOrderUpdating(true);
      var newArr = salesOrder.salesorderline;
      newArr.push(product);
      salesOrder.salesorderline = newArr;
      setSalesOrder({ ...salesOrder });

      var body = {
        '@odata.etag': product['@odata.etag'],
        Document_Type: 'Order',
        Document_No: salesOrder.number,
        Type: 'Item',
        No: product.number,
        Quantity: 1,
        unitPrice: product.unitPrice
      }
      axios.post(`${ServerURL}/sales-orders/add-item`, body, {
        headers: {
          'Authorization': `Bearer ${authProvider.getToken()}`
        }
      }).then(res => {
        updateSalesOrder(salesOrder.id);
      }).catch(err => { console.log(err); setSalesOrderUpdating(false) });
    } else {
      var salesOrderLine = {
        id: product.id,
        Document_Type: "Order",
        Type: 'Item',
        No: product.number,
        Quantity: 1,
        unitPrice: product.unitPrice
      };
      var newList = salesOrder.salesorderline;
      newList.push(salesOrderLine);
      salesOrder.salesorderline = newList;
      setSalesOrder({ ...salesOrder });
    }
  };

  const removeItem = (product) => {
    if (salesOrder.id) {
      setSalesOrderUpdating(true);
      var itemToDelete = activeStep === 0 ? salesOrder.salesorderline.filter(s => s.No === product.number)[0] : product;
      if (itemToDelete) {
        var newArr = salesOrder.salesorderline.filter(s => s.No !== product.number);
        salesOrder.salesorderline = newArr;
        setSalesOrder({ ...salesOrder });
        const toDeleteURL = `${ServerURL}/sales-orders/delete-item/${itemToDelete.id}`;
        axios.delete(toDeleteURL, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          console.log(res.data);
          updateSalesOrder(salesOrder.id);
        }).catch(err => { console.log(err); setSalesOrderUpdating(false) });
      } else {
        setSnackbar({
          message: `Failed to delete item`,
          severity: "error"
        });
      }
    } else {
      var newList = salesOrder.salesorderline.filter(c => c.id !== product.id);
      salesOrder.salesorderline = newList;
      setSalesOrder({ ...salesOrder });
    }
  };

  const updateQuantity = (product, value) => {
    setSalesOrderUpdating(true);
    axios.patch(`${ServerURL}/sales-orders/update-item/${product.id}`, {
      Quantity: parseInt(value)
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      updateSalesOrder(salesOrder.id);
    }).catch(err => { console.log(err); setSalesOrderUpdating(false) });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SelectItems
          handleNext={handleNext}
          categories={categories}
          products={products}
          addItem={addItem}
          removeItem={removeItem}
          selectedCategory={selectedCategory}
          changeCategory={changeCategory}
          existingSalesOrder={salesOrder}
          salesOrderUpdating={salesOrderUpdating}
          itemLinks={itemLinks} />;
      case 1:
        return <CustomerInformation
          handleNext={handleNext}
          handleBack={handleBack}
          saveCustomer={saveCustomer}
          saveSalesPerson={saveSalesPerson}
          saveSalesOrder={saveSalesOrder}
          existingCustomer={user}
          existingSalesPerson={salesPerson}
          existingSalesOrder={salesOrder} />;
      case 2:
        return <ReviewItems
          handleNext={handleNext}
          handleBack={handleBack}
          removeItem={removeItem}
          updateQuantity={updateQuantity}
          existingSalesOrder={salesOrder}
          salesOrderUpdating={salesOrderUpdating}
          isMobile={isMobile} />;
      case 3:
        return <DeliveryDetails handleNext={handleNext} handleBack={handleBack} existingSalesOrder={salesOrder} />;
      case 4:
        if (state?.paidAmount !== null) {
          return <PaymentInformation handleNext={handleNext} handleBack={handleBack} existingSalesOrder={salesOrder} salesOrderUpdating={salesOrderUpdating} isMobile={isMobile} paidAmount={state?.totalPaidAmount} updateSalesOrder={updateSalesOrder} />;
        }
        return null;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {

    if (activeStep === 2) {
      if (!user.email) {
        setSnackbar({
          message: 'There is no email found for this customer. Please proceed to Step 2: Customer Information and fill it out',
          severity: "warning"
        });
        return;
      }
    }
    if (salesOrder) {
      updateSalesOrder(salesOrder.id);
    }
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
      <Helmet>
        <title>1300Washer | Dashboard</title>
      </Helmet>
      <Grid className={isMobile ? classes.mobileRoot : classes.root}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h2"
          pb={2}
          style={{ fontWeight: 'bold' }}
        >
          {orderId ? `Editing sales order: ${orderId}` : 'Create a new order'}
        </Typography>
        <Box>
          {(!state || state.totalPaidAmount === 0) && (
            <Card style={{ padding: '24px 16px' }}>
              <Stepper activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Card>
          )}
          <div>
            <div>
              <Typography component={'div'} className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            </div>
          </div>
        </Box>
      </Grid>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewOrder;
