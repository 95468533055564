import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Card,
  CardContent,
  Button,
  InputAdornment,
  SvgIcon,
  Grid,
  Avatar,
  Typography,
  Divider,
  Paper,
  Tabs,
  Tab,
  CardMedia,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
  Snackbar,
  Alert
} from '@material-ui/core';
import ItemLinks from './ItemLinks';

const headerText1 = 'Equipment will be delivered by each state distributor or nominated certified delivery contractor, unless otherwise arranged with agent. Customer will be contacted within 24hrs to finalise delivery, delivery cost is included in price unless outside of delivery metro zone. If in this case your agent will discuss delivery options with you.'

const headerText2 = 'Please add as many items as you want using the Add To Cart buttons and press the "Confirm & Save" button at the bottom of the page to continue.'

const SelectItems = ({
  handleNext,
  products,
  addItem,
  removeItem,
  categories,
  selectedCategory,
  changeCategory,
  existingSalesOrder,
  salesOrderUpdating,
  itemLinks
}) => {

  const isMobile = useMediaQuery("(max-width:480px)");

  const [snackbar, setSnackbar] = useState({});

  const handleClose = () => {
    setSnackbar({})
  }

  const handleSave = () => {
    if (existingSalesOrder.salesorderline.length) {
      handleNext();
    } else {
      setSnackbar({
        message: "Please add an item before proceeding",
        severity: "warning"
      })
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={true} style={{ padding: 0 }}>
        <Box>
          <Card>
            <CardContent>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  Notice
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {headerText1}
                </Typography>
                <div style={{ marginTop: '10px' }}></div>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {headerText2}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Box sx={{ pt: 3 }}>
        <Paper square>
          {isMobile ? (
            <FormControl variant="outlined" style={{ width: '100%' }} size="small">
              <Select
                id="search-filter"
                value={selectedCategory}
                onChange={(e) => changeCategory(e, e.target.value)}
                name="search-filter"
              >
                {categories && categories?.map((c, i) => (
                  <MenuItem value={i} key={i}>
                    {c.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Tabs
              value={selectedCategory}
              indicatorColor="primary"
              textColor="primary"
              onChange={changeCategory}
            >
              {categories && categories?.map((c, i) => (
                <Tab label={c.displayName} value={i} key={i} disabled={salesOrderUpdating} />
              ))}
            </Tabs>
          )}
        </Paper>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Grid
          container
          spacing={3}
        >
          {products ? products.map((product) => (
            <Grid
              item
              key={product.id}
              lg={3}
              md={6}
              xs={12}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pb: 3
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="150px"
                      width="100px"
                      image={product.ItemURL}
                      style={{ objectFit: 'contain' }}
                    />
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    {product.number}
                  </Typography>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                  >
                    {product.displayName}
                  </Typography>
                  {itemLinks?.length ? (
                    <Grid container style={{ justifyContent: 'center' }}>
                      <ItemLinks
                        itemLinks={itemLinks.filter(il => il.Item_No === product.number)}
                        currentItem={product}
                      />
                    </Grid>
                  ) : null}
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    mt={2}
                  >
                    AU${product.unitPrice?.toFixed(2)}
                  </Typography>
                </CardContent>
                <Box sx={{ flexGrow: 1 }} />
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'center' }}
                  >
                    <Grid
                      item
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {existingSalesOrder.salesorderline?.filter(ci => ci.No === product.number).length ? (
                        <Button
                          variant="contained"
                          fullWidth
                          size="small"
                          style={{ background: 'red' }}
                          onClick={() => removeItem(product)}
                          disabled={salesOrderUpdating}
                        >
                          Remove from cart
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          fullWidth
                          size="small"
                          style={{ background: '#2196f3' }}
                          onClick={() => addItem(product)}
                          disabled={salesOrderUpdating}
                        >
                          Add to cart
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          )) : (
            <Grid sx={{ p: 3 }}>
              No items to show. Please contact administrator if this is incorrect.
            </Grid>

          )}
        </Grid>
      </Box>
      <div style={{ textAlign: 'end', marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          size="large"
        >
          Next
        </Button>
      </div>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SelectItems;
