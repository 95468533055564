import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { ServerURL } from '../theme/api';
import authProvider from '../authProvider';
import moment from 'moment';
import SalespersonReportTable from '../components/reports/salesperson/SalespersonReportTable';
import SalespersonReportChart from '../components/reports/salesperson/SalespersonReportChart';
import SalespersonReportHighlight from '../components/reports/salesperson/SalespersonReportHighlight';
import SalespersonReportMonthChart from '../components/reports/salesperson/SalespersonReportMonthChart';
import { CSVLink, CSVDownload } from 'react-csv';
const axios = require('axios');
const currentYear = moment().format('YYYY');
const csvHeaders = [
  { label: 'Agent Code', key: 'Agent_Code' },
  { label: 'Salesperson Code', key: 'No' },
  { label: 'Name', key: 'Name' },
  { label: 'Total Sales', key: 'Sales' },
  { label: 'January', key: 'January' },
  { label: 'February', key: 'Febuary' },
  { label: 'March', key: 'March' },
  { label: 'April', key: 'April' },
  { label: 'May', key: 'May' },
  { label: 'June', key: 'June' },
  { label: 'July', key: 'July' },
  { label: 'August', key: 'August' },
  { label: 'September', key: 'September' },
  { label: 'October', key: 'October' },
  { label: 'November', key: 'November' },
  { label: 'December', key: 'December' }
];
const SalespersonReport = () => {

  const isMobile = useMediaQuery("(max-width:480px)");
  const [year, setYear] = useState(currentYear);
  const [data, setData] = useState([]);
  const [salesByMonth, setSalesByMonth] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [topPerformer, setTopPerformer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({});

  useEffect(() => {
    if (!loading) setLoading(true);
    axios.get(`${ServerURL}/reports/salesperson/${year}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.data?.salesByPerson.length) {
        var filterPeopleWithSales = res.data.salesByPerson.filter(s => s.Sales !== 0);
        setData(filterPeopleWithSales);
        setTotalSales(res.data.totalSalesAllEmployees);
        setTopPerformer(res.data.topPerformer);
        setSalesByMonth(res.data.salesByMonth);
      }
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: `Failed to fetch report data for sales person in ${year}`,
        severity: "error"
      });
      setLoading(false);
    }).finally(() => setLoading(false));
  }, [year]);

  const handleClose = () => {
    setSnackbar({})
  }

  const generatePDF = () => {

  };

  const renderYears = [...Array(20)].map((x, i) => (
    <MenuItem key={i} value={currentYear - i}>
      {currentYear - i}
    </MenuItem>
  ))

  return (
    <>
      <Helmet>
        <title>1300Washer | Salesperson Report</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: isMobile ? 2 : 3,
          px: 2
        }}
      >
        <Grid container mb={5}>
          <Grid item xs={12} md={6} mb={isMobile ? 3 : 0} style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'left' }}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h2"
              style={{ fontWeight: 'bold', margin: 0, textAlign: isMobile ? 'center' : 'left' }}
            >
              Salesperson Report
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-end' }}>
              <FormControl variant="outlined" style={{ backgroundColor: 'white', marginRight: 30, width: isMobile ? '100%' : 'inherit' }}>
                <InputLabel id="deliveryOption" size="small">Year</InputLabel>
                <Select
                  id="year-filter"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  label="Year"
                  name="year-filter"
                >
                  {renderYears}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginRight: 30, width: isMobile ? '100%' : 'inherit' }}
              >
                <CSVLink
                  data={data}
                  headers={csvHeaders}
                  filename={`salesperson-report.csv`}
                  style={{ color: 'white' }}>
                  Export CSV
                </CSVLink>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={generatePDF}
                size="large"
                style={{ width: isMobile ? '100%' : 'inherit' }}
              >
                Export PDF
              </Button>
            </div>
          </Grid>
        </Grid>
        <SalespersonReportHighlight loading={loading} totalSales={totalSales} topPerformer={topPerformer} />
        <SalespersonReportMonthChart isMobile={isMobile} data={salesByMonth} loading={loading} />
        <SalespersonReportTable isMobile={isMobile} data={data} loading={loading} selectedYear={year} />
        <SalespersonReportChart isMobile={isMobile} data={data} loading={loading} selectedYear={year} />
      </Box>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  )
};

export default SalespersonReport;
