import { Box, Typography, Grid } from '@material-ui/core';
import { videoList } from '../constants/videoList';

const Videos = () => {

  return(
    <Box
      sx={{
        my: 5,
        mx: 5,
        py: 3,
        px: 3,
      }}
      style={{background: 'white', textAlign: 'center'}}
    >
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h2"
      style={{fontWeight: 'bold'}}
    >
      Videos
    </Typography>
    <Grid
      container
      spacing={3}
    >
      {videoList.map(v => (
        <Grid
          item
          key={v.id}
          lg={4}
          md={6}
          xs={12}
        >
          <video width="100%" height="250" controls>
            <source src={v.src} type="video/mp4" width="200px"/>
          </video>
          <Typography
            color="textPrimary"
            variant="h5"
            style={{color: '#F14924', paddingTop: '10px'}}
          >
            {v.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default Videos;
