import React, { useEffect } from 'react';
import {
    Typography
  } from '@material-ui/core';
  import { DataGrid } from '@mui/x-data-grid';

  const ItemSalesReportTable = ({data, isMobile}) => {
    const columns = data?.headerColumns?.splice(2).map((c,i) => {
        return {
            field: c.key,
            headerName: c.key,
            width: 200,
            filterable: false,
            renderCell: (v) => {
                if(v.value) {
                    return <span>{v.value}</span>;
                }
            }
        }
    });
    return(
        <div style={{height: '100%', width: '100%', backgroundColor: 'white', marginTop: isMobile ? 40 : 0}}>
        {data.data ? (
            <DataGrid
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
            rows={data.data}
            columns={columns}
            getRowId={(row) => row.No}
            rowsPerPageOptions={[]}
            autoHeight={true}
            />
        ) : (
            <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            style={{fontWeight: 'bold', margin: 0}}
          >
              No item sales for this year
          </Typography>
        )}
        </div>
    )
  };

  export default ItemSalesReportTable;
