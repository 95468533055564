import { useState, useEffect } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Button,
  InputAdornment,
  SvgIcon,
  CardContent,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  useMediaQuery,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';
import download from 'downloadjs';
const axios = require('axios');


const TransactionHistoryList = () => {
  const [nextLink, setNextLink] = useState(null);
  const [transactionHistories, setTransactionHistories] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState('All');
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [salesPersonLoading, setSalesPersonLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('externalDocumentNumber'); //displayName, postalCode, phoneNumber, externalDocumentNumber
  const [snackbar, setSnackbar] = useState({});
  const isMobile = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    searchTransaction();
  }, [selectedSalesPerson]);


  useEffect(() => {
    axios.get(`${ServerURL}/sales-persons`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setSalesPersonList([{ Code: 'All', Name: 'All Salesperson' }, ...res.data.value]);
    }).catch(err => {
      setSnackbar({
        message: "Failed to fetch Sales persons",
        severity: "error"
      });
      console.log(JSON.stringify(err.response))
      setSalesPersonLoading(false);
    }).finally(() => setSalesPersonLoading(false));
  }, []);

  const handleClose = () => {
    setSnackbar({})
  }

  const searchTransaction = () => {
    setTransactionLoading(true);
    setNextLink(null);
    axios.post(`${ServerURL}/transaction-histories/search`, {
      filter: filter,
      keyword: search.toUpperCase(),
      salespersonCode: selectedSalesPerson
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setTransactionHistories(res.data.value);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      }
    }).catch(err => {
      setSnackbar({
        message: "Failed to fetch transaction history",
        severity: "error"
      });
      console.log(JSON.stringify(err.response))
    }).finally(() => setTransactionLoading(false));
  }

  const loadMoreTransactionHistories = () => {
    setLoadMore(true);
    axios.post(`${ServerURL}/transaction-histories/load-more`, {
      link: nextLink
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      var newArr = transactionHistories.concat(res.data.value);
      setTransactionHistories(newArr);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      } else {
        setNextLink(null);
      }
    }).catch(err => {
      setSnackbar({
        message: "Failed to fetch more transaction history",
        severity: "error"
      });
      console.log(JSON.stringify(err.response))
    }).finally(() => setLoadMore(false));
  };

  const generateInvoice = (inId) => {
    axios.get(`${ServerURL}/transaction-histories/generatePdf/${inId}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      },
      responseType: 'blob'
    }).then(res => {
      download(res.data, `SalesInvoice_${inId}.pdf`, res.headers['content-type']);
    }).catch(err => {
      setSnackbar({
        message: "Failed to generate PDF",
        severity: "error"
      });
      console.log(JSON.stringify(err.response))
    }).finally(() => { });
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Card>
          <CardContent style={isMobile ? { textAlign: 'center' } : { display: 'flex', alignItems: 'center' }}>
            <FormControl variant="outlined" style={isMobile ? { width: '100%', paddingBottom: '20px' } : { width: '200px', marginRight: '30px' }} size={isMobile ? 'small' : 'large'}>
              <Select
                id="select-salesperson"
                value={selectedSalesPerson}
                onChange={(e) => setSelectedSalesPerson(e.target.value)}
                name="select-salesperson"
              >
                {salesPersonLoading && (
                  <MenuItem style={{ justifyContent: 'center' }}>
                    <CircularProgress />
                  </MenuItem>
                )}
                {
                  salesPersonList.map((s, i) => (
                    <MenuItem key={s.Code} value={s.Code}>
                      {s.Name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={isMobile ? { width: '100%' } : { width: '200px', marginRight: '30px' }} size={isMobile ? 'small' : 'large'}>
              <Select
                id="search-filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                name="search-filter"
              >
                <MenuItem value="externalDocumentNumber">
                  Web Order No.
                </MenuItem>
                <MenuItem value="customerName">
                  Name
                </MenuItem>
                <MenuItem value="shipToPostcode">
                  Delivery postcode
                </MenuItem>
                <MenuItem value="phoneNumber">
                  Phone number
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={isMobile ? { width: '100%', pt: 2, pb: 2 } : { maxWidth: 500, width: 500, pr: 3 }}>
              <TextField
                fullWidth
                size={isMobile ? 'small' : 'large'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchTransaction();
                  }
                }}
              />
            </Box>
            <Button
              color="primary"
              variant="contained"
              size={isMobile ? 'small' : 'large'}
              onClick={() => searchTransaction()}
            >
              Search
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Card style={{ marginBottom: '50px' }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Invoice No.
                  </TableCell>
                  <TableCell>
                    Web Order No.
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Phone No.
                  </TableCell>
                  <TableCell>
                    Posting date
                  </TableCell>
                  <TableCell>
                    Delivery postcode
                  </TableCell>
                  <TableCell>
                    Total Ex. GST
                  </TableCell>
                  <TableCell>
                    Total Inc. GST
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionLoading ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (transactionHistories.map((invoice) => (
                  <TableRow
                    hover
                    key={invoice.number}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          <Button onClick={() => { generateInvoice(invoice.id) }}>{invoice.number}</Button>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {invoice.externalDocumentNumber}
                    </TableCell>
                    <TableCell>
                      {invoice.customerName}
                    </TableCell>
                    <TableCell>
                      {invoice.email}
                    </TableCell>
                    <TableCell>
                      {invoice.phoneNumber}
                    </TableCell>
                    <TableCell>
                      {moment(invoice.postingDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>
                      {invoice.shipToPostcode}
                    </TableCell>
                    <TableCell>
                      ${invoice.totalAmountExcludingTax.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      ${invoice.totalAmountIncludingTax.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )))}
                {nextLink && (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                      {loadMore ? (
                        <LinearProgress />
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => loadMoreTransactionHistories()}
                        >
                          Load more
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TransactionHistoryList;
