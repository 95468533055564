// Christmas, New years day.. etc.
// Format: DD-MM
export const fixedHolidays = [
  '01-01', //New Years day
  '26-01', //Australia day
  '25-04', //Anzac day
  '25-12', //Christmas day
  '26-12', //Boxing day
];

//Easter (Changes every year)
//Format: DD-MM-YYYY
export const dynamicHolidays = [
  '15-04-2021',
  '18-04-2021',
  '15-04-2022',
  '18-04-2022',
  '07-04-2023',
  '10-04-2023',
  '29-03-2024',
  '01-04-2024'
];
