import { Card } from '@material-ui/core';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import '../../../theme/recharts.css';

const SalespersonReportMonthChart = ({data, isMobile, loading}) => {

  const CustomTooltip = ({active, payload}) => {
    return(
      <div className="custom-tooltip" style={{backgroundColor: 'white', padding: 10, borderRadius: '4px', border: '2px solid #F14924', fontFamily: "'Roboto', sans-serif"}}>
        <p style={{marginBottom: 5}}><strong>{payload[0]?.payload.Name}</strong></p>
        <p>${payload[0]?.value}</p>
      </div>
    );
  };

  const CustomizedLabel = ({x,y,fill,value}) => {
    if(value > 0) {
      return(
        <text
        x={x + 40}
        y={y}
        dy={-4}
        fontSize='16'
        fontFamily='sans-serif'
        fill={fill}
        textAnchor="middle">${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </text>
      )
    }
    return null;
  };

  return (
      <>
      {(!loading && !isMobile) && (
        <Card style={{padding: 10, marginTop: 30, marginBottom: 30}}>
        <ResponsiveContainer width="100%" height={data.length > 10 ? data.length * 30 : 300}>
        <BarChart data={data}>
          <CartesianGrid
              vertical={false}
              stroke="#ebf3f0"
          />
          <YAxis dataKey="value" tickFormatter={(value) => `$${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} width={100}/>
          <XAxis dataKey="name" type={'category'}/>
          <Bar dataKey="value" fill='#F14924' label={<CustomizedLabel/>}/>
          <Tooltip cursor={{fill: 'rgba(0,0,0,0.2)'}} content={<CustomTooltip/>} />
        </BarChart>
        </ResponsiveContainer>
        </Card>
      )}
      </>
  );
};

export default SalespersonReportMonthChart;
