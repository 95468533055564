import { Box, Typography, Container, Card, CardContent, Grid, CardMedia } from '@material-ui/core';
import {consumerDataPrivacyPolicy, securityPolicy} from '../constants/policyStatements';
const Policies = () => {
  return(
    <Box
      sx={{
        minHeight: '100%',
        py: 5,
        px: 5
      }}
    >
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h2"
      pb={2}
      style={{fontWeight: 'bold'}}
    >
      Policies
    </Typography>
    <Container style={{padding: 0, margin: 0}}>
    <Box>
        <Card style={{padding: 20}}>
          <CardContent>
            <Box>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              style={{marginBottom: 15, paddingBottom: 5, borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
            >
              Security Policy
            </Typography>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {securityPolicy}
                    </Typography>
                    <CardMedia
                      component="img"
                      height="100px"
                      width="200px"
                      image={'/static/images/comodo_secure.png'}
                      style={{objectFit: 'contain', width: '150px'}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              style={{marginBottom: 15, paddingBottom: 5, borderBottom: '1px solid rgba(0, 0, 0, 0.12)', marginTop: 30}}
            >
              Consumer Data Privacy Policy
            </Typography>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {consumerDataPrivacyPolicy}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </Box>
          </CardContent>
        </Card>
    </Box>
    </Container>
    </Box>
  )
};

export default Policies;
