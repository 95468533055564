import {
  LinearProgress
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'Name', headerName: 'Name', width: 200 },
  {
    field: 'January',
    headerName: 'Jan',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'Febuary',
    headerName: 'Feb',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'March',
    headerName: 'Mar',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'Febuary',
    headerName: 'Feb',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'March',
    headerName: 'Mar',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'April',
    headerName: 'Apr',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'May',
    headerName: 'May',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'June',
    headerName: 'Jun',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'July',
    headerName: 'Jul',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'August',
    headerName: 'Aug',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'September',
    headerName: 'Sep',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'October',
    headerName: 'Oct',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'November',
    headerName: 'Nov',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'December',
    headerName: 'Dec',
    width: 120,
    renderCell: (v) => {
      if(v.value > 0) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      } else {
        return <p></p>
      }
    }
  },
  {
    field: 'Sales',
    headerName: 'Total',
    width: 120,
    renderCell: (v) => {
      if(v.row['Sales']) {
        return(
          <span>${v.value.toFixed(2)}</span>
        );
      }
    }
  },
];

const SalespersonReportTable = ({data, isMobile, loading, selectedYear}) => {
  return(
    <div style={{height: '100%', width: '100%', backgroundColor: 'white', marginTop: isMobile ? 40 : 0}}>
    {loading ? (
      <div style={{marginTop: 30}}>
      <LinearProgress/>
      </div>
    ) : (
      <DataGrid
      rows={data}
      columns={columns}
      getRowId={(row) => row.No}
      rowsPerPageOptions={[]}
      sortingOrder={['desc', 'asc', null]}
      autoHeight={true}
      />
    )}
    </div>
  );
};

export default SalespersonReportTable;
