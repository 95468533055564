import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-dates/initialize';
import './theme/calendar.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { ServerURL } from './theme/api';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import moment from 'moment';
const axios = require('axios');

const App = () => {

  const getAuth = () => {
    console.log("current date");
    console.log(moment());
    var token = localStorage.getItem('accessToken');
    if(token) {
      var { exp } = jwt_decode(token);
      const expTime = (exp * 1000) - 60000;
      if(Date.now() >= expTime) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('displayName');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const isLoggedIn = getAuth();
  const content = useRoutes(routes(isLoggedIn));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
