import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import CustomerList from './pages/CustomerList';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import NewOrder from './pages/NewOrder';
import Videos from './pages/Videos';
import SalespersonReport from './pages/SalespersonReport';
import PendingOrders from './pages/PendingOrders';
import ItemSalesReport from './pages/ItemSalesReport';
import TransactionHistory from './pages/TransactionHistory';
import ContactUs from './pages/ContactUs';
import Policies from './pages/Policies';

const routes = (isLoggedIn) => [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: isLoggedIn ? <Navigate to="/app/new-order" /> : <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/new-order" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'customers', element: <CustomerList /> },
      { path: 'new-order', element: <NewOrder/>},
      { path: 'new-order/:orderId', element: <NewOrder/>},
      { path: 'videos', element: <Videos/>},
      { path: 'salesperson-report', element: <SalespersonReport/>},
      { path: 'item-sales-report', element: <ItemSalesReport/>},
      { path: 'transaction-history', element: <TransactionHistory/>},
      { path: 'pending-orders', element: <PendingOrders/>},
      { path: 'contact-us', element: <ContactUs/>},
      { path: 'policies', element: <Policies/>},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
