import { Box, Typography, Container, Card, CardContent, Grid } from '@material-ui/core';

const ContactUs = () => {
  return(
    <Box
      sx={{
        minHeight: '100%',
        py: 5,
        px: 5
      }}
    >
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h2"
      pb={2}
      style={{fontWeight: 'bold'}}
    >
      Contact Us
    </Typography>
    <Container style={{padding: 0, margin: 0}}>
    <Box>
        <Card style={{padding: 20}}>
          <CardContent>
            <Box>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              style={{marginBottom: '30px'}}
            >
              1300WASHER PTY LTD
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                    >
                      Postal:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                    >
                      PO BOX 3409, Darra, Qld 4076
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Phone:
                  </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    1300 927 437
                  </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Email:
                  </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    <a href="mailto:accounts@1300washer.com.au">accounts@1300washer.com.au</a>
                  </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                    >
                      Opening Hours:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    8:00AM - 5:00PM AEST
                  </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </Box>
          </CardContent>
        </Card>
    </Box>
    </Container>
    </Box>
  )
};

export default ContactUs;
