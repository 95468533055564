import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  LinearProgress,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { ServerURL } from '../theme/api';
import authProvider from '../authProvider';
import moment from 'moment';
import { CSVLink, CSVDownload } from 'react-csv';
import ItemSalesReportTable from '../components/reports/itemsales/ItemSalesReportTable';
const axios = require('axios');
const currentYear = moment().format('YYYY');
const ItemSalesReport = () => {

  const isMobile = useMediaQuery("(max-width:480px)");
  const [year, setYear] = useState(currentYear);
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({});

  useEffect(() => {
    if (!loading) setLoading(true);
    axios.get(`${ServerURL}/reports/itemsales/${year}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.data.data.length) {
        setData(res.data);
        setCsvData([...res.data.data])
        setCsvHeaders([...res.data.headerColumns]);
      } else {
        setData([]);
        setCsvHeaders([]);
      }
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: `Failed to fetch report data for item sales in ${year}`,
        severity: "error"
      });
      setLoading(false);
    }).finally(() => setLoading(false));
  }, [year]);

  const handleClose = () => {
    setSnackbar({})
  }

  const generatePDF = () => {

  };

  const renderYears = [...Array(20)].map((x, i) => (
    <MenuItem key={i} value={currentYear - i}>
      {currentYear - i}
    </MenuItem>
  ))

  return (
    <>
      <Helmet>
        <title>1300Washer | Item Sales Report</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: isMobile ? 2 : 3,
          px: 2
        }}
      >
        <Grid container mb={5}>
          <Grid item xs={12} md={6} mb={isMobile ? 3 : 0} style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'left' }}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h2"
              style={{ fontWeight: 'bold', margin: 0, textAlign: isMobile ? 'center' : 'left' }}
            >
              Item Sales Report
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-end' }}>
              <FormControl variant="outlined" style={{ backgroundColor: 'white', marginRight: 30, width: isMobile ? '100%' : 'inherit' }}>
                <InputLabel id="deliveryOption" size="small">Year</InputLabel>
                <Select
                  id="year-filter"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  label="Year"
                  name="year-filter"
                >
                  {renderYears}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginRight: 30, width: isMobile ? '100%' : 'inherit' }}
              >
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={`item-sales-report.csv`}
                  style={{ color: 'white' }}>
                  Export CSV
                </CSVLink>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={generatePDF}
                size="large"
                style={{ width: isMobile ? '100%' : 'inherit' }}
              >
                Export PDF
              </Button>
            </div>
          </Grid>
        </Grid>
        {loading ? <LinearProgress /> : <ItemSalesReportTable data={data} isMobile={isMobile} />}
      </Box>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  )
};

export default ItemSalesReport;
