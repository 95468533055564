import {
   Box,
   Typography,
   Button,
   Grid,
   Card,
   CircularProgress
 } from '@material-ui/core';

const SalespersonReportHighlight = ({totalSales, loading, topPerformer}) => {
  return(
    <Grid container spacing={5}>
      <Grid item xs={12} md={6}>
        <Card style={{padding: 15}}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            style={{fontWeight: 'bold', margin: 0, textAlign: 'center', padding: 10}}
          >
            Total sales
          </Typography>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h2"
            style={{fontWeight: 'bold', margin: 0, textAlign: 'center', color: '#F14924'}}
          >
            {loading ? (
              <CircularProgress/>
            ) : (
              <>
                ${totalSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </>
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{padding: 15}}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            style={{fontWeight: 'bold', margin: 0, textAlign: 'center', padding: 10}}
          >
            Top performer: {loading ? null : (topPerformer?.name ? topPerformer.name : 'N/A')}
          </Typography>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h2"
            style={{fontWeight: 'bold', margin: 0, textAlign: 'center', color: '#F14924'}}
          >
            {loading ? (
              <CircularProgress/>
            ) : (
              <>
                ${topPerformer?.value ? topPerformer.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A'}
              </>
            )}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SalespersonReportHighlight;
