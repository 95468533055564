import { Button, Grid } from '@material-ui/core';
import React from 'react'

export default function ItemLinks({
    itemLinks,
    currentItem
}) {

    return (
    <>
        {itemLinks?.map((il,i) => (
            <Grid item xs={12} md={6} style={{textAlign: 'center'}} key={i}>
                <Button><a href={`https://${il.Link_URL}`} target="_blank" style={{color: '#F14924'}}>{il.Link_description}</a></Button>
            </Grid>
        ))}
    </>
    );

}
