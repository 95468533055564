import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';
import PendingOrdersListResults from '../components/pending-orders/PendingOrdersListResults';

const PendingOrders = () => (
  <>
    <Helmet>
      <title>1300 Washer | Pending Orders</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h2"
        pb={2}
        style={{fontWeight: 'bold'}}
      >
        Pending Orders
      </Typography>
        <Box>
          <PendingOrdersListResults/>
        </Box>
      </Container>
    </Box>
  </>
);

export default PendingOrders;
