const authProvider = {
  getToken: () => {
    return localStorage.getItem('accessToken');
  },
  getDisplayName: () => {
    return localStorage.getItem('displayName');
  }
};

export default authProvider;
