import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  LinearProgress,
  Button,
  InputAdornment,
  SvgIcon,
  CardContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';
const axios = require('axios');

const CustomerListResults = () => {
  const [nextLink, setNextLink] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('customerName'); //customerName, phoneNumber
  const [snackbar, setSnackbar] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios.post(`${ServerURL}/sales-orders/customers/search`, {
      filter: filter,
      keyword: search.toUpperCase()
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setCustomers(res.data.value);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      }
      setCustomerLoading(false);
    }).catch(err => { 
      console.log(JSON.stringify(err.response)); 
      setSnackbar({
        message: "Failed to fetch customers",
        severity: "error"
      });
      setCustomerLoading(false) 
    }).finally(() => setCustomerLoading(false));
  }, []);

  const handleClose = () => {
    setSnackbar({})
  }

  const searchCustomer = () => {
    setCustomerLoading(true);
    setNextLink(null);
    axios.post(`${ServerURL}/sales-orders/customers/search`, {
      filter: filter,
      keyword: search.toUpperCase()
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setCustomers(res.data.value);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      }
      setCustomerLoading(false);
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: "Failed to fetch customers",
        severity: "error"
      }); 
      setCustomerLoading(false) 
    }).finally(() => setCustomerLoading(false));
  }

  const loadMoreCustomers = () => {
    setLoadMore(true);
    axios.post(`${ServerURL}/sales-orders/load-more`, {
      link: nextLink
    }, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      var newArr = customers.concat(res.data.value);
      setCustomers(newArr);
      if (res.data['@odata.nextLink']) {
        setNextLink(res.data['@odata.nextLink']);
      } else {
        setNextLink(null);
      }
    }).catch(err => console.log(err.response)).finally(() => setLoadMore(false));
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Card>
          <CardContent style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl variant="outlined" style={{ width: '200px', marginRight: '30px' }}>
              <Select
                id="search-filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                name="search-filter"
              >
                <MenuItem value="customerName">
                  Name
                </MenuItem>
                <MenuItem value="phoneNumber">
                  Phone number
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ maxWidth: 500, width: 500, pr: 3 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchCustomer();
                  }
                }}
              />
            </Box>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => searchCustomer()}
            >
              Search
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Card style={{ marginBottom: '50px' }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Number
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Phone No.
                  </TableCell>
                  <TableCell>
                    Address
                  </TableCell>
                  <TableCell>
                    Postal Code
                  </TableCell>
                  <TableCell>
                    City
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerLoading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (customers.map((customer, index) => (
                  <TableRow
                    hover
                    key={customer.SellltocustomerNo + index}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          <Button onClick={() => navigate(`/app/new-order/${customer.number}`, { state: { totalPaidAmount: (customer.totalAmountIncludingTax - customer.remainingamt) } })}>{customer.SellltocustomerNo}</Button>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {customer.customer.displayName}
                    </TableCell>
                    <TableCell>
                      {customer.customer.phoneNumber}
                    </TableCell>
                    <TableCell>
                      {customer.customer.addressLine1}
                    </TableCell>
                    <TableCell>
                      {customer.customer.postalCode}
                    </TableCell>
                    <TableCell>
                      {customer.customer.city}
                    </TableCell>
                  </TableRow>
                )))}
                {nextLink && (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                      {loadMore ? (
                        <LinearProgress />
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => loadMoreCustomers()}
                        >
                          Load more
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerListResults;
