import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';
import CustomerListResults from '../components/customer/CustomerListResults';

const CustomerList = () => (
  <>
    <Helmet>
      <title>1300 Washer | Customers</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h2"
        pb={2}
        style={{fontWeight: 'bold'}}
      >
        Store customers
      </Typography>
        <Box>
          <CustomerListResults/>
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerList;
