import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Modal,
  Snackbar,
  Alert
} from '@material-ui/core';
import { ServerURL } from '../../theme/api';
import authProvider from '../../authProvider';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
};

const axios = require('axios');

const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'WA', 'VIC'];

const avoid = ['locality', 'administrative_area_level_2', 'administrative_area_level_1', 'country', 'postal_code'];

var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CustomerInformation = ({ handleNext, handleBack, saveCustomer, saveSalesPerson, saveSalesOrder, existingCustomer, existingSalesPerson, existingSalesOrder }) => {

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyDiiJy9t3xmtl4_W4UrNLG5WfdQbSF3wGE',
    onPlaceSelected: (place) => {
      console.log(place);
      inputAddress(place)
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: 'au' }
    },
  });

  const [personList, setPersonList] = useState([]);
  const [suburbList, setSuburbList] = useState([]);
  const [salesPerson, setSalesPerson] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [suburb, setSuburb] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [email, setEmail] = useState('');
  const [saving, setSaving] = useState(false);

  const [modal, setModal] = useState(false);
  const [foundUser, setFoundUser] = useState(null);

  const [nameTouched, setNameTouched] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState('');

  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState('');

  const [salesPersonTouched, setSalesPersonTouched] = useState(false);
  const [salesPersonError, setSalesPersonError] = useState(false);
  const [salesPersonErrMsg, setSalesPersonErrMsg] = useState('');

  const [postcodeTouched, setPostcodeTouched] = useState(false);
  const [postcodeError, setPostcodeError] = useState(false);
  const [postcodeErrMsg, setPostcodeErrMsg] = useState('');

  const [addressTouched, setAddressTouched] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressErrMsg, setAddressErrMsg] = useState(false);

  const [emailTouched, setEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState('');

  const [suburbTouched, setSuburbTouched] = useState(false);
  const [suburbError, setSuburbError] = useState(false);
  const [suburbErrMsg, setSuburbErrMsg] = useState('');

  const [suburbLoading, setSuburbLoading] = useState(false);
  const [personLoading, setPersonLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({});

  useEffect(() => {
    axios.get(`${ServerURL}/sales-persons`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      setPersonList(res.data.value);
      if (existingSalesPerson) {
        setSalesPerson(existingSalesPerson);
      }
    }).catch(err => {
      console.log(JSON.stringify(err.response));
      setSnackbar({
        message: "Failed to fetch sales person",
        severity: "error"
      });
    }).finally(() => setPersonLoading(false));
  }, []);

  useEffect(() => {
    if (existingCustomer) {
      setSaving(true);
      updateSuburb(existingCustomer.postalCode, existingCustomer.city);
    };
  }, []);

  useEffect(() => {
    setPhoneNumber(existingCustomer ? existingCustomer.phoneNumber : '');
    setName(existingCustomer ? existingCustomer.displayName : '');
    setState(existingCustomer ? existingCustomer.state : '');
    setPostcode(existingCustomer ? existingCustomer.postalCode : '');
    setAddress(existingCustomer ? existingCustomer.addressLine1 : '');
    setAddress2(existingCustomer ? existingCustomer.addressLine2 : '');
    setEmail(existingCustomer ? existingCustomer.email : '');
    setNameTouched(existingCustomer ? true : false);
    setPhoneNumberTouched(existingCustomer ? true : false);
    setSalesPersonTouched(existingCustomer ? true : false);
    setPostcodeTouched(existingCustomer ? true : false);
    setAddressTouched(existingCustomer ? true : false);
    setEmailTouched(existingCustomer ? true : false);
    setSuburbTouched(existingCustomer ? true : false);
    if (existingCustomer) {
      updateSuburb(existingCustomer.postalCode, existingCustomer.city);
    }
  }, [existingCustomer]);

  const handleClose = () => {
    setSnackbar({})
  }

  const checkUserExist = (phoneNum) => {
    axios.get(`${ServerURL}/customers/check-customer-exist/${phoneNum}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (res.data) {
        setModal(true);
        setFoundUser(res.data);
      } else {
        setModal(false);
        setFoundUser(null);
      }
    }).catch(err => {
      console.log(err);
      setModal(false);
      setFoundUser(null);
    })
  };

  const inputAddress = (place) => {
    console.log(place);
    const unitNumber = place.address_components[0]?.long_name;
    const streetName = place.address_components[1]?.long_name;
    const fullAddress = `${unitNumber} ${streetName}`;
    setAddress(fullAddress);
  };

  const updateSuburb = (pc, sub) => {
    setSuburbLoading(true);
    axios.get(`${ServerURL}/post-codes/${pc}`, {
      headers: {
        'Authorization': `Bearer ${authProvider.getToken()}`
      }
    }).then(res => {
      if (!res.data.value.length) {
        setPostcodeError(true);
        setPostcodeErrMsg('Please enter valid postcode');
        setSuburb('');
        setSuburbList([]);
        setSuburbTouched(false);
      } else {
        setPostcodeError(false);
        setPostcodeErrMsg('');
        setSuburbList(res.data.value);
        setSuburb(sub ? sub : '');
        if (!sub) setSuburbTouched(false);
        setState(res.data.value[0].County);
      }
    }).finally(() => { setSuburbLoading(false); setSaving(false) });
  };

  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'postcode':
        setPostcodeTouched(true);
        if (e.target.value.length !== 4) {
          setPostcodeError(true);
          setPostcodeErrMsg('Please enter valid postcode');
        } else {
          setPostcodeError(false);
          setPostcodeErrMsg('');
          updateSuburb(e.target.value, null);
        }
        break;
      case 'email':
        setEmailTouched(true);
        if (!emailReg.test(e.target.value)) {
          setEmailError(true);
          setEmailErrMsg('Please enter valid email');
        } else {
          setEmailError(false);
          setEmailErrMsg('');
        }
        break;
      case 'salesperson':
        setSalesPersonTouched(true);
        if (e.target.value === '') {
          setSalesPersonError(true);
          setSalesPersonErrMsg('Please select a sales person');
        } else {
          setSalesPersonError(false);
          setSalesPersonErrMsg('');
        }
        break;
      case 'username':
        setNameTouched(true);
        if (e.target.value === '') {
          setNameError(true);
          setNameErrMsg('Please enter name');
        } else {
          setNameError(false);
          setNameErrMsg('');
        }
        break;
      case 'phonenumber':
        setPhoneNumberTouched(true);
        if (e.target.value === '') {
          setPhoneNumberError(true);
          setPhoneNumberErrMsg('Please enter phone number');
        } else {
          checkUserExist(e.target.value);
          setPhoneNumberError(false);
          setPhoneNumberErrMsg('');
        }
        break;
      case 'suburb':
        setSuburbTouched(true);
        if (e.target.value === '') {
          setSuburbError(true);
          setSuburbErrMsg('Please select a suburb');
        } else {
          setSuburbError(false);
          setSuburbErrMsg('');
        }
        break;
      case 'address':
        setAddressTouched(true);
        if (e.target.value === '') {
          setAddressError(true);
          setAddressErrMsg('Please enter address');
        } else {
          setAddressError(false);
          setAddressErrMsg('');
        }
        break;
    }
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'salesperson':
        setSalesPerson(e.target.value);
        break;
      case 'phonenumber':
        setPhoneNumber(e.target.value);
        break;
      case 'username':
        setName(e.target.value);
        break;
      case 'postcode':
        setPostcode(e.target.value);
        break;
      case 'suburb':
        setSuburb(e.target.value);
        break;
      case 'address':
        setAddress(e.target.value);
        break;
      case 'address2':
        setAddress2(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      default:
        setSnackbar({
          message: "Unknown field",
          severity: "warning"
        });
        break;
    }
  };

  const renderSuburbs = suburbList.map((s, i) => (
    <MenuItem value={s.City} key={s.City}>{s.City}</MenuItem>
  ));

  const handleSave = () => {
    if (nameTouched
      && phoneNumberTouched
      && salesPersonTouched
      && postcodeTouched
      && addressTouched
      && emailTouched
      && suburbTouched
      && !nameError
      && !phoneNumberError
      && !salesPersonError
      && !postcodeError
      && !addressError
      && !emailError
      && !suburbError) {
      var newObj = {
        displayName: name.toUpperCase(),
        type: 'Person',
        addressLine1: address.toUpperCase(),
        addressLine2: address2.toUpperCase(),
        city: suburb,
        postalCode: postcode,
        state: state,
        phoneNumber: phoneNumber,
        email: email
      };
      setSaving(true);
      if (existingCustomer) {
        var newObj2 = {
          displayName: existingCustomer.displayName.toUpperCase(),
          type: 'Person',
          addressLine1: existingCustomer.addressLine1.toUpperCase(),
          addressLine2: existingCustomer.addressLine2?.toUpperCase(),
          city: existingCustomer.city,
          postalCode: existingCustomer.postalCode,
          state: existingCustomer.state,
          phoneNumber: existingCustomer.phoneNumber,
          email: existingCustomer.email
        };
        var sameInfo = JSON.stringify(newObj) === JSON.stringify(newObj2);
        if (!sameInfo) {
          axios.patch(`${ServerURL}/customers/update/${existingCustomer.id}`,
            newObj, {
            headers: {
              'Authorization': `Bearer ${authProvider.getToken()}`
            }
          }).then(res => {
            saveCustomer(res.data);
            saveSalesPerson(salesPerson);
            createSalesOrder(salesPerson, res.data, existingSalesOrder);
          }).catch(err => {
            console.log(JSON.stringify(err.response));
            setSnackbar({
              message: "Failed to update customer",
              severity: "error"
            });
            setSaving(false);
          });
        } else {
          createSalesOrder(salesPerson, existingCustomer, existingSalesOrder);
        }
      } else {
        setSaving(true);
        axios.post(`${ServerURL}/customers/create`,
          newObj, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          saveCustomer(res.data);
          saveSalesPerson(salesPerson);
          createSalesOrder(salesPerson, res.data, existingSalesOrder);
        }).catch(err => {
          console.log(JSON.stringify(err.response));
          setSnackbar({
            message: "Failed to create customer",
            severity: "error"
          });
          setSaving(false)
        });
      }
    } else {
      if (!nameTouched) {
        setNameError(true);
        setNameErrMsg('Required field');
      }
      if (!phoneNumberTouched) {
        setPhoneNumberError(true);
        setPhoneNumberErrMsg('Required field');
      }
      if (!salesPersonTouched) {
        setSalesPersonError(true);
        setSalesPersonErrMsg('Required field');
      }
      if (!postcodeTouched) {
        setPostcodeError(true);
        setPostcodeErrMsg('Required field');
      }
      if (!addressTouched) {
        setAddressError(true);
        setAddressErrMsg('Required field');
      }
      if (!emailTouched) {
        setEmailError(true);
        setEmailErrMsg('Required field');
      }
      if (!suburbTouched) {
        setSuburbError(true);
        setSuburbErrMsg('Required field');
      }
    }
  };

  const createSalesOrder = (inSalesPerson, inCustomer, inOrder) => {
    if (!inOrder.id) {
      var dateToday = moment().format("YYYY-MM-DD");
      const removeId = inOrder.salesorderline.map(({ id, ...keep }) => keep);
      var salesOrderObj = {
        orderDate: dateToday,
        SellltocustomerNo: inCustomer.number,
        Document_Type: 'Order',
        salespersonCode: salesPerson,
        salesorderline: removeId,
        deliveryOption: 'To Customer',
        emailUpdates: true,
        Web_Order: true,
      };
      axios.post(`${ServerURL}/sales-orders/new-order`,
        salesOrderObj, {
        headers: {
          'Authorization': `Bearer ${authProvider.getToken()}`
        }
      }).then(res => {
        if (!res.data.error) {
          saveSalesOrder(res.data);
          handleNext();
        } else {
          setSnackbar({
            message: 'Something went wrong when creating sales order.',
            severity: "error"
          });
        }
      }).catch(err => {
        console.log(JSON.stringify(err.response));
        setSnackbar({
          message: "Failed to create new sales order",
          severity: "error"
        }); 
      }).finally(() => setSaving(false));
    } else {
      //If sales order exist, update the email in sales order to match the email in customer information
      if (inCustomer.email !== inOrder.email) {
        axios.patch(`${ServerURL}/sales-orders/update-order/${inOrder.id}`, {
          email: inCustomer.email
        }, {
          headers: {
            'Authorization': `Bearer ${authProvider.getToken()}`
          }
        }).then(res => {
          if (!res.data.error) {
            handleNext();
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        handleNext();
      }
    }

  };

  const renderSalesPeople = personList.map((p, i) => (
    <MenuItem value={p.Code} key={p.Code}>{p.Name}</MenuItem>
  ));

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 5,
        px: 5,
        mt: 5,
        backgroundColor: 'white'
      }}
    >
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h4"
      >
        Enter customer information.
      </Typography>
      <Grid container item xs={12} lg={6} style={{ marginTop: '20px' }}>
        <FormControl variant="outlined" fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
          <InputLabel id="sales-person" size="small">Sales person</InputLabel>
          <Select
            id="sales-person"
            value={salesPerson}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Sales person"
            name="salesperson"
            size="small"
            disabled={saving}
            error={salesPersonError}
          >
            {personLoading && (
              <MenuItem style={{ justifyContent: 'center' }}>
                <CircularProgress />
              </MenuItem>
            )}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {renderSalesPeople}
          </Select>
          {salesPersonError && (
            <p style={{
              fontWeight: 400,
              fontSize: '0.75rem',
              lineHeight: 1.66,
              letterSpacing: '0.03333em',
              textAlign: 'left',
              marginTop: '4px',
              marginRight: '14px',
              marginBottom: 0,
              marginLeft: '14px',
              color: '#d32f2f'
            }}>{salesPersonErrMsg}</p>
          )}
        </FormControl>
        <TextField
          fullWidth
          label="Customer phone number"
          margin="normal"
          name="phonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          type="number"
          value={phoneNumber}
          variant="outlined"
          size="small"
          disabled={saving}
          error={phoneNumberError}
          helperText={phoneNumberError && phoneNumberErrMsg}
        />
        <TextField
          fullWidth
          label="Customer Full Name"
          margin="normal"
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={name}
          variant="outlined"
          size="small"
          disabled={saving}
          error={nameError}
          helperText={nameError && nameErrMsg}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <TextField
          fullWidth
          label="Postcode"
          margin="normal"
          name="postcode"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={postcode}
          variant="outlined"
          size="small"
          error={postcodeError}
          helperText={postcodeError && postcodeErrMsg}
          disabled={saving}
        />
        <TextField
          fullWidth
          label="State"
          margin="normal"
          name="state"
          type="text"
          value={state}
          variant="outlined"
          size="small"
          disabled={true}
          InputLabelProps={{ shrink: state ? true : false }}
        />
        <FormControl variant="outlined" fullWidth style={{ marginTop: '16px', marginBottom: '8px' }}>
          <InputLabel id="suburb" size="small">Suburb</InputLabel>
          <Select
            id="suburb"
            value={suburb}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Suburb"
            name="suburb"
            size="small"
            disabled={saving}
            error={suburbError}
          >
            {suburbLoading && (
              <MenuItem>
                <CircularProgress />
              </MenuItem>
            )}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {renderSuburbs}
          </Select>
        </FormControl>
        {suburbError && (
          <p style={{
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            textAlign: 'left',
            marginTop: '4px',
            marginRight: '14px',
            marginBottom: 0,
            marginLeft: '14px',
            color: '#d32f2f'
          }}>{suburbErrMsg}</p>
        )}
        <TextField
          fullWidth
          label="Address"
          margin="normal"
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          inputRef={materialRef}
          type="text"
          value={address}
          variant="outlined"
          size="small"
          disabled={saving}
          error={addressError}
          helperText={addressError && addressErrMsg}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <span style={{
          fontSize: '0.9em',
          color: 'grey'
        }}>**Please manually type in address if the dropdown does not show the correct address after selection.</span>
        <TextField
          fullWidth
          label="Address 2"
          margin="normal"
          name="address2"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={address2}
          variant="outlined"
          size="small"
          disabled={saving}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          value={email}
          variant="outlined"
          size="small"
          error={emailError}
          helperText={emailErrMsg}
          disabled={saving}
        />
      </Grid>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleBack} size="large" disabled={saving}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          size="large"
          disabled={saving}
        >
          Next
        </Button>
      </div>

      {(foundUser && modal) && (<Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
            Existing user found. Use details?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }} component="div">
            <p>Name: {foundUser.displayName}</p>
            <p>Email: {foundUser.email}</p>
            <p>Address: {foundUser.addressLine1}</p>
            <p>Address 2: {foundUser.addressLine2}</p>
            <p>City: {foundUser.city}</p>
            <p>Postcode: {foundUser.postalCode}</p>
            <p>State: {foundUser.state}</p>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  saveCustomer(foundUser);
                  setModal(false);
                }}
                size="large"
                style={{ width: '100%' }}
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setModal(false);
                }}
                size="large"
                style={{ width: '100%' }}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      )}
      {saving && (
        <div style={{ marginTop: '20px' }}>
          <LinearProgress />
        </div>
      )}
      <Snackbar open={!!snackbar?.message} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
};

export default CustomerInformation;
